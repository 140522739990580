import styled, { keyframes, css } from 'styled-components'
import {
  space,
  color,
  typography,
  border,
  layout,
  flexbox,
} from 'styled-system'

const fadeIn = keyframes`
0% {
  transform: scaleY(.005) scaleX(0);
}
50% {
  transform: scaleY(.005) scaleX(1);
}
100% {
  transform: scaleY(1) scaleX(1);
}
`
const fadeOut = keyframes`
0% {
  transform: scaleY(1) scaleX(1);
}
50% {
  transform: scaleY(.005) scaleX(1);
}
100% {
  transform: scaleY(.005) scaleX(0);
}
`

export const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 5000;
  opacity: 0.6;
  background: black;
  cursor: pointer;
  transition: opacity 0.25s ease-in-out;
  ${space}
  ${layout}
  ${border}
  ${typography}
  ${color}
  ${flexbox}

  &:hover {
    opacity: ${({ isMounted }) => isMounted && 0.75};
  }

  transform: ${({ isMounted }) =>
    isMounted ? 'scaleY(0.01) scaleX(0)' : 'scale(1)'};
  animation: ${({ isMounted }) =>
    isMounted
      ? css`
          ${fadeIn} 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards
        `
      : css`
          ${fadeOut} 1s .3s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards
        `};
`
