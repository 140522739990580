import React from 'react'
import PropTypes from 'prop-types'
import TableStatsRow from './TableStatsRow'

const TableStats = ({ tableRows = [], ...rest }) => {
  return (
    <>
      {tableRows?.map(({ id, ...restRow }) => (
        <TableStatsRow key={id} {...rest} {...restRow} />
      ))}
    </>
  )
}

TableStats.propTypes = {
  tableRows: PropTypes.arrayOf(
    PropTypes.shape({
      rowHeaders: PropTypes.arrayOf(PropTypes.string),
      rowEntry: PropTypes.objectOf(PropTypes.array),
    })
  ),
}

export default TableStats
