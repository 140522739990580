import styled from 'styled-components'
import theme from '@theme'

import { space } from 'styled-system'

export const ToolTip = styled.div`
  pointer-events: none;
  position: absolute !important;
  padding: 4px 13px;
  background: ${p => (p.mode ? '#000' : 'rgba(0,0,0,0.1)')};
  color: grey;
  border-radius: 5px;
  font-size: 14px;
  top: -30px;
  right: ${p => !p.alignCenter && '0px'};
  opacity: ${p => (p.hasCopied ? 1 : 0)};
  transform: ${p => (p.hasCopied ? 'translateY(-3px)' : 'none')};
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

  &::after {
    content: '';
    position: absolute !important;
    left: 0;
    right: ${p => (p.alignCenter ? '0' : '-30px')};
    bottom: -6px;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid ${p => (p.mode ? '#000' : 'rgba(0,0,0,0.1)')};

    @media (max-width: 815px) {
      transform: scale(${p => (p.isDarkMode ? 0.92 : 0)});
    }
  }
`

export const IconWrapper = styled.a`
  opacity: 1;
  position: relative;
  border-radius: 5px;
  width: 24px;
  padding-right: 20px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  ${space}
`

export const MoonOrSun = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: ${p => (p.isDarkMode ? '4px' : '2px')} solid
    ${p => (p.theme ? theme.colors[p.theme].text : theme.colors.light.text)};
  transform: scale(${p => (p.isDarkMode ? 0.55 : 1)});
  transition: all 0.45s ease;
  overflow: ${p => (p.isDarkMode ? 'visible' : 'hidden')};

  &::before {
    content: '';
    position: absolute;
    right: -9px;
    top: -9px;
    height: 24px;
    width: 24px;
    border: 2px solid
      ${p => (p.theme ? theme.colors[p.theme].text : theme.colors.light.text)};
    border-radius: 50%;
    transform: translate(${p => (p.isDarkMode ? '14px, -14px' : '0, 0')});
    opacity: ${p => (p.isDarkMode ? 0 : 1)};
    transition: transform 0.45s ease;
  }

  &::after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: -4px 0 0 -4px;
    position: absolute;
    top: 50%;
    left: 50%;
    box-shadow: 0 -23px 0 ${p => (p.theme ? theme.colors[p.theme].text : theme.colors.light.text)},
      0 23px 0
        ${p => (p.theme ? theme.colors[p.theme].text : theme.colors.light.text)},
      23px 0 0
        ${p => (p.theme ? theme.colors[p.theme].text : theme.colors.light.text)},
      -23px 0 0 ${p => (p.theme ? theme.colors[p.theme].text : theme.colors.light.text)},
      15px 15px 0
        ${p => (p.theme ? theme.colors[p.theme].text : theme.colors.light.text)},
      -15px 15px 0 ${p => (p.theme ? theme.colors[p.theme].text : theme.colors.light.text)},
      15px -15px 0 ${p => (p.theme ? theme.colors[p.theme].text : theme.colors.light.text)},
      -15px -15px 0
        ${p => (p.theme ? theme.colors[p.theme].text : theme.colors.light.text)};

    transform: scale(${p => (p.isDarkMode ? 1 : 0)});
    transition: all 0.35s ease;

    @media (max-width: 768px) {
      transform: scale(${p => (p.isDarkMode ? 0.92 : 0)});
    }
  }
`

export const MoonMask = styled.div`
  position: absolute;
  right: -1px;
  top: -8px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 0;
  background: ${p =>
    p.isDarkMode ? theme.colors.light.bg : theme.colors.dark.bg};
  transform: translate(${p => (p.isDarkMode ? '14px, -14px' : '0, 0')});
  opacity: ${p => (p.isDarkMode ? 0 : 1)};
  transition: background 0.2s linear, transform 0.45s ease;
`
