import React from 'react'
import PropTypes from 'prop-types'
import Box from '@components/Box'
import { Label, Title, Body } from '@components/RichTextAndTypography'
import { trunc } from '@utils'

const Iframe = ({ isMobile, url, theme }) => {
  return (
    <Box
      width={isMobile ? '100%' : '72%'}
      bg={`${theme}.bgAltLight`}
      padding={!isMobile && '10px'}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <iframe
        aria-label="soundcloud snippet"
        width="100%"
        height="170px"
        scrolling="no"
        frameBorder="no"
        src={`https://w.soundcloud.com/player/?url=${url}&auto_play=false&hide_related=false&show_comments=true&show_user=true&sharing=true&color=F215A1&show_teaser=true`}
      ></iframe>
    </Box>
  )
}

const SoundCloudItem = ({
  isMobile,
  title = '',
  link = '',
  linkType = '',
  displayDateAndShare = false,
  publicationDate = '',
  excerpt = {},
  label = 'Podcast',
  cta = '',
  theme = 'dark',
  ...rest
}) => {
  return (
    <>
      <Box
        padding="5px"
        width="100%"
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems="center"
        bg={`${theme}.bgAltLight`}
      >
        <Iframe isMobile={isMobile} url={link} theme={theme} />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent={isMobile && 'center'}
          height="180px"
          width={isMobile ? '100%' : '28%'}
          bg={`${theme}.bg`}
          pl="10px"
          pt="15px"
        >
          {label && (
            <Label
              fontSize={[1]}
              fontWeight="normal"
              color={`${theme}.heroAltLight`}
            >
              {label}
            </Label>
          )}
          {title && (
            <Title mt={1} fontSize={[5]} color={`${theme}.text`}>
              {title}
            </Title>
          )}
          <Body fontSize={2} mt={3} fontWeight="normal" color={`${theme}.text`}>
            {trunc(excerpt?.excerpt, 200)}
          </Body>
          {displayDateAndShare && publicationDate && (
            <Box fontSize={1} mt={3} color={`${theme}.peripheralText`}>
              {publicationDate}
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

SoundCloudItem.propTypes = {
  isMobile: PropTypes.bool,
  title: PropTypes.string,
  link: PropTypes.string,
  linkType: PropTypes.string,
  excerpt: PropTypes.object,
  displayDateAndShare: PropTypes.bool,
  publicationDate: PropTypes.string,
  label: PropTypes.string,
  cta: PropTypes.string,
  theme: PropTypes.string,
}

export default SoundCloudItem
