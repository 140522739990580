import React from 'react'
import Box from '@components/Box'
import { useMobile } from '@hooks'
import ColumnImage from './components/ColumnImage'
import ColumnVideo from './components/ColumnVideo'
import ColumnText from './components/ColumnText'
import ColumnList from './components/ColumnList'
import Embed from '@components/Embed'
import { Title } from '@components/RichTextAndTypography'

import { StyledH3 } from './styles'

import { handleRemoveContentfulFromContentTypeString } from '@utils/'

const ColumnContent = ({ contentType, type, ...rest }) => {
  contentType = handleRemoveContentfulFromContentTypeString(contentType || type)
  switch (contentType) {
    case 'uiImage':
    case 'image':
      return <ColumnImage {...rest} />
    case 'uiVideo':
    case 'video':
      return <ColumnVideo {...rest} />
    case 'componentColumnEmbed':
    case 'columnEmbed':
    case 'embed':
      return <Embed {...rest} />
    case 'componentColumnText':
    case 'columnText':
      return <ColumnText {...rest} />
    case 'componentColumnList':
    case 'columnList':
    case 'list':
      return <ColumnList {...rest} />
    default:
      return null
  }
}

const shouldDisplaytitle = (contentType, displayTitle, title) => {
  contentType = handleRemoveContentfulFromContentTypeString(contentType)

  if (contentType === 'columnList') {
    return displayTitle && title
  } else return title
}

const TwoColumn = ({ title, displayTitle, columns, ...rest }) => {
  const [isMobile] = useMobile()

  return (
    <>
      {displayTitle && title && (
        <Box>
          <Title fontSize={5} mb={4}>
            {title}
          </Title>
        </Box>
      )}
      <Box
        display={isMobile ? 'flex' : 'grid'}
        flexDirection="column"
        gridTemplateColumns={
          !isMobile ? `repeat(2, minmax(130px ,1fr))` : '100vw'
        }
        gridColumnGap="16px"
        width="100%"
      >
        {columns?.map(
          (
            {
              id,
              title,
              displayTitle,
              contentType,
              type,
              textVerticalAlignment,
              ...fields
            },
            i
          ) => (
            <>
              {shouldDisplaytitle(contentType, displayTitle, title) && (
                <Box gridColumn={i + 1} mb={3}>
                  <StyledH3 fontSize={6}>{title}</StyledH3>
                </Box>
              )}
              <Box
                key={`${id}+${i}`}
                width="100%"
                gridColumn={i + 1}
                gridRow={2}
                mb={isMobile ? 3 : 0}
              >
                <ColumnContent
                  contentType={contentType || type}
                  fullWidth={isMobile}
                  {...fields}
                  {...rest}
                />
              </Box>
            </>
          )
        )}
      </Box>
    </>
  )
}

export default TwoColumn
