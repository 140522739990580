import React from 'react'
import PropTypes from 'prop-types'
import TableBlock from '@components/Table'

const flattenTableData = array => {
  if (!array) return [null, null]
  const headers = array?.[0]
  const cellData = array.length > 0 && array.slice(1)
  const arrayToObj = {}
  let maxLength = 0
  let currLen = 0
  if (headers) {
    headers.forEach((h, i) => {
      arrayToObj[h] = []

      cellData.forEach(c => {
        arrayToObj[h].push(c[i])
        currLen = arrayToObj[h].length
        if (maxLength < currLen) maxLength = currLen
      })
    })
  }
  return [arrayToObj, maxLength]
}

const shapeTableRows = ({ rowEntry, ...rest }) => {
  const [flattenedData, dataLevel] =
    rowEntry && flattenTableData(rowEntry?.tableData)
  return {
    ...rest,
    rowEntry: flattenedData,
    rowHeaders: Object.keys(flattenedData),
  }
}

const TableGatsbyCDAContainer = ({ tables, ...rest }) => {
  tables = tables.map(({ tableRows, ...rest }) => ({
    ...rest,
    tableRows: tableRows?.map(table => shapeTableRows(table)),
  }))

  return <TableBlock tables={tables} {...rest} />
}

TableGatsbyCDAContainer.propTypes = {}

export default TableGatsbyCDAContainer
