import React from 'react'
import PropTypes from 'prop-types'
import Box from '@components/Box'
import { Label, Field, Error, GroupTitle } from './Input'
import { StyledSpan } from '../styles.js'

const InputRadio = ({ theme, layout, label, name, options, isRequired }) => {
  return (
    <Box>
      <GroupTitle>
        {label}
        {isRequired && '*'}
      </GroupTitle>
      <Box display="grid" gridTemplateColumns="1fr 1fr" gridGap="1rem">
        {options?.map((option, i) => {
          return (
            <Label
              theme={theme}
              key={i}
              type="radio"
              display="block"
              position="relative"
              pl="17px"
              textTransform="capitalize"
              fontSize={2}
              letterSpacing="normal"
            >
              <Field
                theme={theme}
                name={name}
                type="radio"
                value={option}
                position="absolute"
                opacity="0"
                height="0"
                width="0"
              />
              <StyledSpan
                position="absolute"
                top="0"
                left="0"
                height="11px"
                width="11px"
                background-color="white"
                color="darkestBlack"
                border="solid 1px black"
                borderRadius="50%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                bg="white"
              />
              <Box pb={1}>{option}</Box>
            </Label>
          )
        })}
      </Box>
      <Error theme={theme} name={name} />
    </Box>
  )
}

InputRadio.propTypes = {
  theme: PropTypes.string,
  layout: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  isRequired: PropTypes.bool,
}

export default InputRadio
