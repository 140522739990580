import styled from 'styled-components'
import {
  space,
  position,
  color,
  typography,
  border,
  layout,
  flexbox,
  shadow,
} from 'styled-system'
import { Link } from 'gatsby'

export const StyledHeadline = styled.h1`
  ${space} 
  ${typography}
  ${color}
  ${position}
  ${border}
  ${layout}
  ${shadow}
  ${flexbox}
`

export const StyledA = styled.a`
  box-sizing: border-box;
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`
export const StyledGatsbyLink = styled(Link)`
  box-sizing: border-box;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 2px;
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledSubhead = styled.div`
  box-sizing: border-box;
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledQuoteIntro = styled.div`
  box-sizing: border-box;
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledLabel = styled.div`
  letter-spacing: 3.75px;
  text-transform: uppercase;
  box-sizing: border-box;
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledTitle = styled.h2`
  box-sizing: border-box;
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledButtonText = styled.div`
  box-sizing: border-box;
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledBodyCopy = styled.div`
  box-sizing: border-box;
  overflow-wrap: break-word;
  word-wrap: break-word;
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledSecondaryBody = styled.div`
  box-sizing: border-box;
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledLargeBody = styled.div`
  box-sizing: border-box;
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledBody = styled.p`
  overflow-wrap: break-word;
  word-wrap: break-word;
  box-sizing: border-box;
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledSmallBody = styled.div`
  box-sizing: border-box;
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledH1 = styled.h1`
  ${color}
  ${layout}
  ${typography}
  ${shadow}
  ${space}


`
export const StyledH2 = styled.h2`
  ${color}
  ${layout}
  ${typography}
  ${shadow}
  ${space}

`
export const StyledH3 = styled.h3`
  ${color}
  ${layout}
  ${typography}
  ${shadow}
  ${space}

`
export const StyledH4 = styled.h4`
  ${color}
  ${layout}
  ${typography}
  ${shadow}
  ${space}


`
export const StyledH5 = styled.h5`
  ${color}
  ${layout}
  ${typography}
  ${space}
  ${shadow}
`
export const StyledH6 = styled.h6`
  ${color}
  ${layout}
  ${typography}
  ${shadow}
  ${space}
  text-transform: uppercase;

`
export const StyledP = styled.p`
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
  ${shadow}
`
export const StyledButton = styled.button`
  ${color}
  ${layout}
  ${typography}
  ${border}

  &:hover {
    background-color: #0025435c;
  }
`
export const StyledUL = styled.ul`
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
  ${shadow}
  list-style-type: disc;
  & li::marker {
    font-size: 80%;
  }

  li > * {
    display: inline;
    padding: 0;
    height: 100%;
    vertical-align: middle;
  }
`

export const StyledOL = styled.ol`
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
  list-style-type: decimal;
  & li::marker {
    font-size: 60%;
  }

  li > * {
    display: inline;
    padding: 0;
    height: 100%;
  }
`

export const StyledLI = styled.li`
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
  ${shadow}
  box-sizing: border-box;
`

export const StyledB = styled.b`
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
  ${shadow}

`

export const StyledI = styled.i`
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
  ${shadow}

`

export const StyledU = styled.u`
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
  ${shadow}
`
