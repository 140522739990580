import React from 'react'
import PropTypes from 'prop-types'
import ContentGroupGatsbyCDAContainer from '@containers/ContentGroup'

const EmbeddedContentGroupSoundCloudContainer = ({
  sources,
  preview,
  ...rest
}) => {
  return (
    <ContentGroupGatsbyCDAContainer
      items={sources}
      preview={preview}
      layout="Soundcloud"
      {...rest}
    />
  )
}

EmbeddedContentGroupSoundCloudContainer.propTypes = {
  sources: PropTypes.arrayOf(PropTypes.object),
  preview: PropTypes.bool,
}

export default EmbeddedContentGroupSoundCloudContainer
