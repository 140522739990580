import React from 'react'
import PropTypes from 'prop-types'
import Box from '@components/Box'
import Image from '@components/Image'
import SimpleBody from './SimpleBody'
import SimpleCtaAndDate from './SimpleCtaAndDate'
import SimpleMedia from './SimpleMedia'
import { StyledImgContainer } from '../styles'

import { useMobile } from '@hooks/'
import { LinkWrapper, LinkDiv } from '@components/Button'

const SimpleCard = ({
  name = '',
  hideMedia = false,
  theme = 'light',
  image = '',
  alt = '',
  external = false,
  link = '',
  itemsLength,
  displayDateAndShare = false,
  hideLink,
  category,
  topic,
  video,
  ...rest
}) => {
  const [isMobile] = useMobile()

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Box
        width="auto"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        <Box display="flex" flexDirection="column" flex="1">
          {!hideMedia && (
            <SimpleMedia
              fullWidth={isMobile}
              image={image}
              video={video}
              alt={alt}
              link={link}
              external={external}
            />
          )}
          <Box display="flex" alignItems="center" height="40px" minWidth="20px">
            <LinkDiv
              fontWeight={400}
              color={`${theme?.toLowerCase()}.heroAlt`}
              letterSpacing="2px"
              to={!external && link}
              href={external && link}
              fontSize={['12px', 1]}
              style={{ textTransform: 'uppercase' }}
            >
              {topic?.title || category?.title}
            </LinkDiv>
          </Box>
          <SimpleBody external={external} link={link} theme={theme} {...rest} />
        </Box>

        {(!hideLink || displayDateAndShare) && (
          <SimpleCtaAndDate
            theme={theme}
            hideLink={hideLink}
            displayDateAndShare={displayDateAndShare}
            link={link}
            external={external}
            {...rest}
          />
        )}
      </Box>
    </Box>
  )
}

SimpleCard.propTypes = {
  id: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  link: PropTypes.string,
  alt: PropTypes.string,

  theme: PropTypes.string,
  displayDateAndShare: PropTypes.bool,
}

export default SimpleCard
