import React, { useState } from 'react'
import { Formik } from 'formik'
import axios from 'axios'
import css from '@styled-system/css'
import Box from '@components/Box'
import { useLocale, useWindowReady } from '@hooks'
import Inputs from './Inputs'
import Buttons, { buttonTypes } from './Buttons'
import { layouts, initialValues, validationSchema } from './utils'
import {
  StyledForm,
  StyledHolyGrailLayout,
  StyledMailPhoneLinks,
} from './styles'
import { Title } from '@components/RichTextAndTypography'
import { navigate } from 'gatsby'
import Icon from '@components/Icon'

const API = process.env.GATSBY_MAIL_TO_URL

const Form = ({ theme, layout, formFields, formButton }) => {
  const { getLocalizedSlug } = useLocale()
  const windowReady = useWindowReady()

  /*
   * Removing Reset and Password types for now
   * (Still available in CMS)
   */
  const validFieldTypes = [
    'Text',
    'Email',
    'Tel',
    'TextArea',
    'Radio',
    'Checkbox',
    'Dropdown',
  ]

  const fields = formFields?.filter(field =>
    validFieldTypes.includes(field.type)
  )

  /* Server State Handling */
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})
  const [error, setError] = useState(false)

  const redirect = async values => {
    navigate(`/${getLocalizedSlug(formButton?.navigationLink?.slug)}`, {
      state: { values },
    })
  }

  const submit = async (values, actions) => {
    setLoading(true)
    try {
      const response = await axios({
        method: 'POST',
        url: API,
        headers: {
          'Content-Type': 'application/json',
        },
        data: values,
      })
      // Success 🎉
      actions.setSubmitting(false)
      setData(response.data)
      actions.resetForm()
      setLoading(false)
      setError(false)
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        actions.setSubmitting(false)
        setLoading(false)
        setError(true)
        setData(error.response.data.error)
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        actions.setSubmitting(false)
        setLoading(false)
        setError(true)
        setData(error.response.data.error)
      } else {
        // Something happened in setting up the request and triggered an Error
        actions.setSubmitting(false)
        setLoading(false)
        setError(true)
        setData(error.response.data.error)
      }
      actions.setSubmitting(false)
      setLoading(false)
      setError(true)
      setData(error.response.data.error)
    }
  }

  const handleOnSubmit = (values, actions) => {
    switch (formButton.buttonType) {
      case buttonTypes.REDIRECT:
        redirect(values)
        break
      case buttonTypes.SUBMISSION:
        submit(values, actions)
        break
    }
  }

  return windowReady ? (
    <Formik
      initialValues={initialValues(fields)}
      validationSchema={validationSchema(fields)}
      onSubmit={handleOnSubmit}
    >
      {({ isSubmitting }) => (
        <StyledForm>
          <Inputs theme={theme} layout={layout} fields={fields} />
          <Buttons
            theme={theme}
            layout={layout}
            button={formButton}
            loading={loading}
            error={error}
            isSubmitting={isSubmitting}
          />
        </StyledForm>
      )}
    </Formik>
  ) : null
}

const FormWrapper = ({
  theme = 'dark',
  layout,
  title,
  formFields,
  formButton,
  ...rest
}) => {
  switch (layout) {
    case layouts.CONTACT:
      return (
        <StyledHolyGrailLayout paddingTop="20px" marginLeft="10px">
          <Box flex="1" maxWidth="600px" marginRight="2em" paddingBottom="5em">
            <Form
              theme={theme}
              layout={layout}
              formFields={formFields}
              formButton={formButton[0]}
              {...rest}
            />
          </Box>
          <Box>
            <Box
              display="block"
              fontSize="15px"
              fontFamily="headings"
              fontWeight={1}
              letterSpacing="2px"
              color={`${theme}.text` || 'black'}
              mb="5px"
            >
              PASADENA
            </Box>
            <Box
              display="block"
              fontSize="20px"
              fontFamily="headings"
              fontWeight="300"
              letterSpacing="0px"
              lineHeight="32px"
              color={`${theme}.text` || 'black'}
              mb="2px"
            >
              <a
                href="tel:1-626-678-9033"
                rel="noreferrer noopener"
                target="_blank"
                css={css({
                  color: `${theme}.formAsideColor`,
                  '&:hover': {
                    color: `${theme}.formAsideHover`,
                  },
                })}
              >
                +1 626 678 9033
              </a>
              <br />
              <a
                href="mailto:info@space.camp"
                rel="noreferrer noopener"
                target="_blank"
                css={css({
                  color: `${theme}.formAsideColor`,
                  '&:hover': {
                    color: `${theme}.formAsideHover`,
                  },
                })}
              >
                info@space.camp
              </a>
            </Box>
            <Box display="flex" flexDirectio="row">
              <a
                href="https://www.instagram.com/wearespacecamp/?hl=en"
                rel="noreferrer noopener"
                target="_blank"
                css={css({
                  color: `${theme}.formAsideColor`,
                  '&:hover': {
                    color: `${theme}.formAsideHover`,
                  },
                })}
              >
                <Icon
                  glyph="instagram"
                  fill="currentColor"
                  viewBox="2 2 21.03 19.8"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/spacecamp"
                rel="noreferrer noopener"
                target="_blank"
                css={css({
                  color: `${theme}.formAsideColor`,
                  '&:hover': {
                    color: `${theme}.formAsideHover`,
                  },
                })}
              >
                <Icon
                  glyph="linkedIn"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                />
              </a>
            </Box>
          </Box>
        </StyledHolyGrailLayout>
      )
    case layouts.FOOTER:
      return (
        <Box my={5}>
          <Title mb={4}>{title}</Title>
          <Form
            theme={theme}
            layout={layout}
            formFields={formFields}
            formButton={formButton[0]}
            {...rest}
          />
        </Box>
      )
    case layouts.SUBSCRIBE:
    default:
      return (
        <Box my={5}>
          <Title mb={4}>{title}</Title>
          <Form
            theme={theme}
            layout={layout}
            formFields={formFields}
            formButton={formButton[0]}
            {...rest}
          />
        </Box>
      )
  }
}

export default FormWrapper
