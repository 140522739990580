import React from 'react'
import PropTypes from 'prop-types'
import Embed from '@components/Embed'

const EmbeddedEmbedContainer = ({ ...rest }) => {
  return <Embed {...rest} />
}

EmbeddedEmbedContainer.propTypes = {}

export default EmbeddedEmbedContainer
