import React from 'react'
import PropTypes from 'prop-types'
import Image from '@components/Image'

const ImageGatsbyCDAContainer = props => {
  return <Image {...props} />
}

ImageGatsbyCDAContainer.propTypes = {}

export default ImageGatsbyCDAContainer

ImageGatsbyCDAContainer.PropTypes = {}
