import React from 'react'
import PropTypes from 'prop-types'
import Box from '@components/Box'
import { Label, Field, Error } from './Input'
import { layouts } from '../utils'

const InputText = ({
  theme,
  layout,
  type,
  label,
  name,
  placeholder,
  isRequired,
}) => {
  switch (layout) {
    case layouts.FOOTER:
      return (
        <Box display="inline-block">
          <Field
            theme={theme}
            name={name}
            type={type}
            placeholder={placeholder}
            isRequired={isRequired}
            width="auto"
            mt="0"
            mr=".5rem"
            minWidth="228px"
            border="solid 1px"
            borderColor="black"
            bg="white"
          />
          <Error theme={theme} name={name} />
        </Box>
      )
    default:
      return (
        <Box display="block">
          <Label theme={theme} htmlFor={name}>
            {label}
            {isRequired && '*'}
          </Label>
          <Field
            theme={theme}
            name={name}
            type={type}
            placeholder={placeholder}
            isRequired={isRequired}
            customStyles={{
              '&:focus': {
                color: 'black',
                bg: `${theme}.formFocus`,
                border: 'solid 1px black',
              },
              transition: 'background-color 0.2s, border-color 0.2s',
            }}
          />
          <Error theme={theme} name={name} />
        </Box>
      )
  }
}

InputText.propTypes = {
  theme: PropTypes.string,
  layout: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
}

export default InputText
