import React from 'react'
import EmbeddedCTAContainer from './EmbeddedCTAContainer'
import EmbeddedFormContainer from './EmbeddedFormContainer'
import EmbeddedImageContainer from './EmbeddedImageContainer'
import EmbeddedPullQuoteContainer from './EmbeddedPullQuoteContainer'
import EmbeddedVideoContainer from './EmbeddedVideoContainer'
import EmbeddedContentGroupContainer from './EmbeddedContentGroupContainer'
import EmbeddedContentGroupSoundCloudContainer from './EmbeddedContentGroupSoundCloudContainer'
import EmbeddedContentGroupImageVideoGalleryContainer from './EmbeddedContentGroupImageVideoGalleryContainer'
import EmbeddedTableContainer from './EmbeddedTableContainer'
import EmbeddedModuleTwoColumnContainer from './EmbeddedModuleTwoColumnContainer'
import EmbeddedEmbedContainer from './EmbeddedEmbedContainer'
import EmbeddedContentGroupCardsListContainer from './EmbeddedContentGroupCardsListContainer'
import { handleRemoveContentfulFromContentTypeString } from '@utils/dataFormatting'

const EmbeddedEntrySubContainer = ({ contentType, ...rest }) => {
  /*
   * data has been sanitized and formatted to be in the GatsbyCDA shape
   * regardless of whether it came from EmbeddedEntryGatsbyCDAContainer or EmbeddedEntryApolloCPAContainer
   * all of the following subcontainers are using GatsbyCDA containers
   * preview prop has been passed down, for any additional re-mapping (like for cta links)
   */

  contentType = handleRemoveContentfulFromContentTypeString(contentType)

  switch (contentType) {
    case 'moduleCardsListManual':
      return <EmbeddedContentGroupCardsListContainer {...rest} />
    // case 'cardBlock': // TODO: remove/flatten
    //   return <EmbeddedContentGroupContainer {...rest} />
    case 'moduleCta':
      return <EmbeddedCTAContainer {...rest} />
    case 'moduleForm':
      return <EmbeddedFormContainer {...rest} />
    case 'uiImage':
      return <EmbeddedImageContainer {...rest} />
    case 'modulePullQuote':
      return <EmbeddedPullQuoteContainer {...rest} />
    case 'uiVideo':
      return <EmbeddedVideoContainer {...rest} />
    case 'moduleTableBlock':
    case 'moduleTableAccordionOrResultsBlock':
      return <EmbeddedTableContainer {...rest} />
    case 'moduleTwoColumnShowcase':
      return <EmbeddedModuleTwoColumnContainer {...rest} />
    case 'moduleGallery': // gatsby-source-contentful
      return <EmbeddedContentGroupImageVideoGalleryContainer {...rest} />
    case 'componentColumnEmbed':
    case 'columnEmbed':
      return <EmbeddedEmbedContainer {...rest} />
    case 'moduleSoundCloud':
      return <EmbeddedContentGroupSoundCloudContainer {...rest} />
    default:
      return null
  }
}

EmbeddedEntrySubContainer.propTypes = {}

export default EmbeddedEntrySubContainer
