import React from 'react'
import PropTypes from 'prop-types'
import { ContentGroupImageVideoGalleryGatsbyCDAContainer } from '@containers/ContentGroupImageVideoGallery'

const EmbeddedContentGroupCarouselContainer = ({ items, preview, ...rest }) => {
  return (
    <ContentGroupImageVideoGalleryGatsbyCDAContainer
      items={items}
      preview={preview}
      isEmbedded
      {...rest}
    />
  )
}

EmbeddedContentGroupCarouselContainer.propTypes = {}

export default EmbeddedContentGroupCarouselContainer
