import React from 'react'
import {
  StyledHeadline,
  StyledQuoteIntro,
  StyledLabel,
  StyledTitle,
  StyledSubhead,
  StyledButtonText,
  StyledBodyCopy,
  StyledSecondaryBody,
  StyledLargeBody,
  StyledBody,
  StyledSmallBody,
  StyledH2,
  StyledH3,
  StyledH4,
  StyledH5,
  StyledH6,
  StyledB,
  StyledU,
  StyledI,
  StyledUL,
  StyledLI,
  StyledOL,
  StyledA,
  StyledGatsbyLink,
} from './styles'
import RichText, { renderRichText } from './RichTextAndTypography'
import Box from '@components/Box'
export default RichText
export { renderRichText }

const LabelLink = ({ children, theme, isMobile, href, to, ...rest }) => {
  if (href) {
    return (
      <StyledA
        mb={2}
        fontSize={2}
        fontWeight={400}
        minWidth="20px"
        color="heroColor"
        letterSpacing="2px"
        href={href}
        textTransform="uppercase"
        {...rest}
      >
        {children}
      </StyledA>
    )
  }
  return (
    <StyledGatsbyLink
      mb={2}
      fontSize={2}
      fontWeight={400}
      minWidth="20px"
      color="heroColor"
      letterSpacing="2px"
      to={to}
      textTransform="uppercase"
      {...rest}
    >
      {children}
    </StyledGatsbyLink>
  )
}

const Label = ({ children, theme = 'light', isMobile, ...rest }) => {
  return (
    <Box
      mb={2}
      fontSize={['12px', 2]}
      fontWeight={400}
      minWidth="20px"
      color={`${theme}.heroAlt`}
      letterSpacing="2px"
      style={{ textTransform: 'uppercase' }}
      {...rest}
    >
      {children}
    </Box>
  )
}

const H2 = ({ children, theme, isMobile, isArticle }) => {
  return (
    <StyledH2
      fontSize={['28px', '50px']}
      fontFamily="headings"
      m={0}
      mb="0"
      pt={['20px', '40px']}
      paddingBottom={['20px', '40px']}
      color={`${theme}.text` || 'black'}
      lineHeight={['34px', 5]}
      fontWeight={600}
      textShadow={theme === 'dark' && `text`}
    >
      {children}
    </StyledH2>
  )
}

const H3 = ({ children, theme, isMobile, isArticle, ...rest }) => {
  return (
    <StyledH3
      fontSize={['20px', 7, 7, 7, 7, 7]}
      fontFamily="headings"
      m={0}
      paddingBottom="10px"
      color={`${theme}.text` || 'black'}
      lineHeight={['26px', '40px']}
      fontWeight={600}
      textShadow={theme === 'dark' && `text`}
      maxWidth={!isMobile && '75%'}
      {...rest}
    >
      {children}
    </StyledH3>
  )
}

const H4 = ({ children, theme, isMobile, ...rest }) => {
  return (
    <StyledH4
      fontSize={[5, 5, 5, 5, 5, 5]}
      fontFamily="headings"
      m={0}
      color={`${theme}.text` || 'black'}
      lineHeight={[4]}
      fontWeight={300}
      textShadow={theme === 'dark' && `text`}
      {...rest}
    >
      {children}
    </StyledH4>
  )
}

const H5 = ({ children, theme, isMobile, ...rest }) => {
  return (
    <StyledH5
      fontSize={[8, 8, 8, 8, 8, 8]}
      fontFamily="headings"
      m={0}
      color={`${theme}.text` || 'black'}
      lineHeight={[0]}
      fontWeight={600}
      textShadow={theme === 'dark' && `text`}
      {...rest}
    >
      {children}
    </StyledH5>
  )
}

const H6 = ({ children, theme, isMobile, ...rest }) => {
  return (
    <StyledH6
      fontSize={[8, 8, 8, 8, 8, 8]}
      fontFamily="headings"
      m={0}
      color={`${theme}.text` || 'black'}
      lineHeight={[0]}
      fontWeight={600}
      textShadow={theme === 'dark' && `text`}
      {...rest}
    >
      {children}
    </StyledH6>
  )
}
const B = ({ children, theme, isMobile, ...rest }) => {
  return (
    <StyledB
      fontSize={[3, '24px']}
      fontFamily="headings"
      m={0}
      color={`${theme}.text` || 'black'}
      lineHeight={[0]}
      fontWeight={600}
      textShadow={theme === 'dark' && `text`}
      {...rest}
    >
      {children}
    </StyledB>
  )
}
const U = ({ children, theme, isMobile, ...rest }) => {
  return (
    <StyledU
      fontSize={[3, '24px']}
      fontFamily="headings"
      m={0}
      color={`${theme}.text` || 'black'}
      lineHeight={[0]}
      fontWeight={600}
      textShadow={theme === 'dark' && `text`}
      {...rest}
    >
      {children}
    </StyledU>
  )
}

const I = ({ children, theme, isMobile, ...rest }) => {
  return (
    <StyledI
      fontSize={[3, '24px']}
      fontFamily="headings"
      m={0}
      color={`${theme}.text` || 'black'}
      lineHeight={[0]}
      fontWeight={600}
      textShadow={theme === 'dark' && `text`}
      {...rest}
    >
      {children}
    </StyledI>
  )
}

const UL = ({ children, theme, isMobile, ...rest }) => {
  return (
    <Box pl={'35px'}>
      <StyledUL
        fontSize={[8, 8, 8, 8, 8, 8]}
        fontFamily="headings"
        m={0}
        color={`${theme}.text` || 'black'}
        lineHeight={[0]}
        fontWeight={600}
        textShadow={theme === 'dark' && `text`}
        {...rest}
      >
        {children}
      </StyledUL>
    </Box>
  )
}

const LI = ({ children, theme, isMobile, ...rest }) => {
  return (
    <StyledLI
      fontSize={[8, 8, 8, 8, 8, 8]}
      fontFamily="headings"
      m={0}
      color={`${theme}.text` || 'black'}
      lineHeight={[0]}
      fontWeight={600}
      textShadow={theme === 'dark' && `text`}
      {...rest}
    >
      {children}
    </StyledLI>
  )
}

const OL = ({ children, theme, isMobile, ...rest }) => {
  return (
    <Box pl={'35px'}>
      <StyledOL
        fontSize={[8, 8, 8, 8, 8, 8]}
        fontFamily="headings"
        m={0}
        color={`${theme}.text` || 'black'}
        lineHeight={[0]}
        fontWeight={600}
        textShadow={theme === 'dark' && `text`}
        {...rest}
      >
        {children}
      </StyledOL>
    </Box>
  )
}

const Headline = ({
  children,
  theme,
  isMobile,
  isArticle = false,
  ...rest
}) => {
  return (
    <StyledHeadline
      fontSize={isArticle ? ['34px', '34px', '75px', 10] : [7, 10]}
      fontFamily="headings"
      m={0}
      p={[0]}
      color={`${theme}.text` || 'black'}
      lineHeight={[0]}
      fontWeight={600}
      marginLeft={isArticle && !isMobile && '-5px'}
      maxWidth={!isMobile && '75%'}
      textShadow={'rgb(0 0 0 / 50%) 0px 0px 35px'}
      {...rest}
    >
      {children}
    </StyledHeadline>
  )
}

const QuoteIntro = ({ children, theme, isMobile, ...rest }) => {
  return (
    <StyledQuoteIntro
      fontSize={[8, 8, 8, 8, 8, 8]}
      fontFamily="headings"
      fontWeight="bold"
      color={`${theme}.text` || 'black'}
      {...rest}
    >
      {children}
    </StyledQuoteIntro>
  )
}

const Title = ({ children, theme, ...rest }) => {
  return (
    <StyledTitle
      fontSize={5}
      fontsFamily="headings"
      fontWeight="bold"
      color={`${theme}.text` || 'black'}
      m="0"
      p="0"
      {...rest}
    >
      {children}
    </StyledTitle>
  )
}

const Subhead = ({ children, theme, ...rest }) => {
  return (
    <StyledSubhead
      fontSize={[5, 5, 5, 5, 5, 5]}
      fontsFamily="headings"
      fontWeight="bold"
      color={`${theme}.text` || 'black'}
      {...rest}
    >
      {children}
    </StyledSubhead>
  )
}

const ButtonText = ({ children, theme, ...rest }) => {
  return (
    <StyledButtonText
      fontSize={[3, 3, 3, 3, 3, 3]}
      fontsFamily="body"
      fontWeight="bold"
      color={`${theme}.text` || 'black'}
      {...rest}
    >
      {children}
    </StyledButtonText>
  )
}

const BodyCopy = ({ children, theme, ...rest }) => {
  return (
    <StyledBodyCopy
      fontSize={[3, 3, 3, 3, 3, 3]}
      fontsFamily="body"
      fontWeight="regular"
      color={`${theme}.text` || 'black'}
      {...rest}
    >
      {children}
    </StyledBodyCopy>
  )
}

const SecondaryBody = ({ children, theme, ...rest }) => {
  return (
    <StyledSecondaryBody
      fontSize={[0, 0, 0, 0, 0, 0]}
      fontsFamily="body"
      fontWeight="regular"
      color={`${theme}.text` || 'black'}
      {...rest}
    >
      {children}
    </StyledSecondaryBody>
  )
}

const LargeBody = ({ children, theme, ...rest }) => {
  return (
    <StyledLargeBody
      fontSize={[7, 7, 7, 7, 7, 7]}
      fontsFamily="body"
      fontWeight="bold"
      color={`${theme}.text` || 'black'}
      {...rest}
    >
      {children}
    </StyledLargeBody>
  )
}

const Body = ({ children, theme, ...rest }) => {
  return (
    <StyledBody
      fontSize={[3, 3, 3, 3, 3, 3]}
      fontsFamily="body"
      fontWeight="regular"
      color={`${theme}.text` || 'black'}
      {...rest}
    >
      {children}
    </StyledBody>
  )
}
const SmallBody = ({ children, theme, ...rest }) => {
  return (
    <StyledSmallBody
      fontSize={[0, 0, 0, 0, 0, 0]}
      fontsFamily="body"
      fontWeight="regular"
      color={`${theme}.text` || 'black'}
      {...rest}
    >
      {children}
    </StyledSmallBody>
  )
}

export {
  Headline,
  QuoteIntro,
  Label,
  Title,
  Subhead,
  ButtonText,
  BodyCopy,
  SecondaryBody,
  LargeBody,
  Body,
  SmallBody,
  H2,
  H3,
  H4,
  H5,
  H6,
  B,
  U,
  I,
  UL,
  LI,
  OL,
  LabelLink,
}
