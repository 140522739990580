import React from 'react'
import PropTypes from 'prop-types'
import ModuleTwoColumnGatsbyCDAContainer from '@containers/ModuleTwoColumnContainer'

const EmbeddedModuleTwoColumnContainer = props => {
  return <ModuleTwoColumnGatsbyCDAContainer {...props} />
}

EmbeddedModuleTwoColumnContainer.propTypes = {}

export default EmbeddedModuleTwoColumnContainer
