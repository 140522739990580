import React from 'react'
import PropTypes from 'prop-types'
import { FormGatsbyCDAContainer } from '@containers/Form'

const EmbeddedFormContainer = props => {
  return <FormGatsbyCDAContainer {...props} />
}

EmbeddedFormContainer.propTypes = {}

export default EmbeddedFormContainer
