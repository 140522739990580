import React from 'react'
import PropTypes from 'prop-types'
import Card from '@components/Card'

const ListItem = ({ layout, ...rest }) => {
  switch (layout) {
    case 'List - Full':
      return <Card cardLayout={layout} {...rest} />
    case 'List - Minimal':
      return <Card cardLayout={layout} {...rest} />
  }
}

ListItem.propTypes = {}

export default ListItem
