import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import usePreviewQuery, { GET_ENTRY_BY_ID } from '@hooks/usePreviewQuery'
import { recursivelyGetLinkedEntriesAndSpreadFields } from '@utils/'
import EmbeddedEntrySubContainer from './subcontainers'
import { useLocale } from '@hooks/'

const EmbeddedEntryApolloCPAContainer = ({ node, ...rest }) => {
  const id = node?.data?.target?.sys?.id
  const { locale } = useLocale()
  const { loading, error, data } = usePreviewQuery({
    query: GET_ENTRY_BY_ID,
    id: id,
    skip: !id,
    locale: locale,
  })
  const [entryFields, setEntryFields] = useState({})

  useEffect(() => {
    if (!loading && !error && data) {
      const { items, includes } = data.entry
      const { sys, fields } = items?.[0]

      let embeddedEntry = {
        ...fields,
        id: sys?.id,
        contentType: sys?.contentType?.sys?.id,
      }

      const newEntryFields = recursivelyGetLinkedEntriesAndSpreadFields(
        embeddedEntry,
        includes?.Entry || [],
        includes?.Asset || []
      )
      setEntryFields(newEntryFields)
    }
  }, [data, error, loading])

  return <EmbeddedEntrySubContainer {...rest} {...entryFields} preview />
}

EmbeddedEntryApolloCPAContainer.propTypes = {}

export default EmbeddedEntryApolloCPAContainer
