import styled from 'styled-components'
import {
  space,
  position,
  color,
  typography,
  border,
  layout,
  flexbox,
  variant,
} from 'styled-system'

export const StyledH2 = styled.h2`
  margin: 0 !important;
  padding: 0 !important;
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${variant}
`

export const StyledH3 = styled.h3`
  margin: 0 !important;
  padding: 0 !important;
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${variant}
`

export const StyledP = styled.p`
  margin: 0 !important;
  padding: 0 !important;
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${variant}
`
