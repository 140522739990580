import React from 'react'
import PropTypes from 'prop-types'
import CtaGatsbyCDAContainer from '@containers/Cta/CtaGatsbyCDAContainer'

const EmbeddedCTAContainer = props => {
  return <CtaGatsbyCDAContainer {...props} />
}

EmbeddedCTAContainer.propTypes = {}

export default EmbeddedCTAContainer
