import React from 'react'
import PropTypes from 'prop-types'
import { StyledLI, StyledP, StyledH3, StyledShare } from '../styles'
import Box from '@components/Box'
import { Logo, A, LabelButton } from '@components/Button'
import { ShareButton } from '@components/Controls'

const Cta = ({
  callToActionAppearance = '',
  callToActionText = '',
  ctaLink = '',
  external = false,
  theme = 'light',
}) => {
  switch (callToActionAppearance) {
    case 'Link':
      return (
        <Box display="flex" flexDirection="row" alignItems="center">
          <A to={!external && ctaLink} href={external && ctaLink}>
            {callToActionText}
          </A>
        </Box>
      )
    case 'Button':
      return (
        <LabelButton
          margin="0"
          to={!external && ctaLink}
          href={external && ctaLink}
        >
          {callToActionText}
        </LabelButton>
      )
    default:
      return null
  }
}

const DateTimeAndShare = ({
  authorName,
  authorSlug,
  date,
  theme,
  link,
  authorBy,
  external,
}) => {
  let sanitizedLink = link?.[0] === '/' ? link?.substring(1) : link
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent={date || authorName ? 'space-between' : 'flex-end'}
      alignItems="center"
      mt={2}
      fontWeight="normal"
    >
      <Box display="flex" flexDirection="row" justifyContent="space-apart">
        {authorName && (
          <Box
            display="flex"
            flexDirection="row"
            color={`${theme}.peripheralText`}
          >
            <StyledP fontWeight="normal" fontSize={1} mr="3px">
              {authorBy}
            </StyledP>
            <Logo
              display="flex"
              alignItems="center"
              justifyContent="center"
              fontWeight="normal"
              fontSize={1}
              to={authorSlug}
              style={{ textDecoration: 'underline' }}
            >
              {authorName}
            </Logo>
          </Box>
        )}
        {date && authorName && (
          <Box
            borderRightColor={`${theme}.peripheralText`}
            borderRightStyle="solid"
            borderRightWidth="1px"
            mx={2}
          />
        )}
        {date && (
          <Box fontSize={1} color={`${theme}.peripheralText`}>
            {date}
          </Box>
        )}
      </Box>
      <StyledShare height="40px" display="flex">
        <ShareButton
          m="0"
          external={external}
          shortUrl={sanitizedLink}
          color="white"
        />
      </StyledShare>
    </Box>
  )
}

const ListCardMinimal = ({
  name = '',
  title = '',
  theme = 'Light',
  displayDateAndShare = false,
  displayCta = 'No CTA',
  ctaText = 'Learn More',
  author = false,
  publicationDate = false,
  category = '',
  external = false,
  link = '',
  authorBy = 'BY',
}) => {
  return (
    <StyledLI
      display="flex"
      flexDirection={['column', 'column', 'row']}
      flexShrink={0}
      bg="darkestBlack"
      width={['80%', '80%', '100%']}
      mb={[0, 0, 2]}
      mr={[3, 3, 0]}
      height={displayDateAndShare ? '150px' : '120px'}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width="100%"
        height="100%"
        bg={`${theme}.bg`}
        fontWeight="bold"
        p={[0, 0, 3]}
        px={[2, 2, 3]}
      >
        <Box
          mt={2}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          {category && <StyledP mb={2}>{category}</StyledP>}
          {name && <StyledP> {name} </StyledP>}
          {title && <StyledH3 mt="0">{title}</StyledH3>}
        </Box>
        <Box mt={3}>
          {displayCta !== 'No CTA' && (
            <Cta
              callToActionAppearance={displayCta}
              callToActionText={ctaText}
              ctaLink={link}
              external={external}
              theme={theme}
            />
          )}
          {displayDateAndShare && (
            <DateTimeAndShare
              authorName={author?.name}
              authorSlug={author?.slug}
              date={publicationDate}
              theme={theme}
              link={link}
              external={external}
              authorBy={authorBy}
            />
          )}
        </Box>
      </Box>
    </StyledLI>
  )
}

ListCardMinimal.propTypes = {
  name: PropTypes.string,
  author: PropTypes.shape({
    id: PropTypes.string,
    excerpt: PropTypes.string,
    name: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string,
  }),
  category: PropTypes.string,
  link: PropTypes.string,
  title: PropTypes.string,
  publicationDate: PropTypes.string,
  theme: PropTypes.string,
  displayDateAndShare: PropTypes.bool,
  displayCta: PropTypes.string,
  ctaText: PropTypes.string,
}

export default ListCardMinimal
