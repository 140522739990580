import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@components/Box'
import { Title } from '@components/RichTextAndTypography'
import { useMobile, useTranslatedTerms } from '../../hooks'
import CardGroupMapping from './CardGroupMapping'
import Button from '@components/Button'
import { StyledGroupContainer } from './styles'

const CardGroup = ({
  title = '',
  displayTitle = true,
  items = [{}],
  theme = 'dark',
  mobileLimit = 4,
  desktopLimit = 12,
  ...rest
}) => {
  const itemLength = items.length
  const [shownIndex, setShownIndex] = useState(0)
  const { getTranslatedTerm } = useTranslatedTerms()
  const [isMobile] = useMobile()
  const slice = isMobile ? mobileLimit : desktopLimit

  let tempLength = itemLength
  let partitionedItems = []
  let prevStartSliceIndex = 0
  let sliceIndex = slice

  while (true) {
    if (tempLength <= slice) {
      partitionedItems.push(items.slice(prevStartSliceIndex, itemLength))
      break
    } else {
      partitionedItems.push(items.slice(prevStartSliceIndex, sliceIndex))
      prevStartSliceIndex = prevStartSliceIndex + slice
      sliceIndex = sliceIndex + slice
      tempLength = tempLength - slice
    }
  }
  const partitionedItemsLength = partitionedItems.length

  return (
    <StyledGroupContainer className="Card Group" width="100%" mt={5}>
      {displayTitle && title && <Title fontSize={5}>{title}</Title>}
      {partitionedItems?.slice(0, shownIndex + 1)?.map((items, i) => (
        <CardGroupMapping
          items={items}
          key={`${i}-cardgroupmapping`}
          {...rest}
        />
      ))}
      {partitionedItemsLength !== 1 && partitionedItemsLength > shownIndex + 1 && (
        <Box width="100%" display="flex" justifyContent="center" mt={4}>
          <Button
            m={0}
            color={`${theme}.indexBtnColor`}
            hover={`${theme}.indexBtnHoverColor`}
            borderColor="white"
            borderWidth="1px"
            borderStyle="solid"
            bg={`${theme}.indexBtnBg`}
            hoverBg={`${theme}.indexBtnHover`}
            onClick={() => setShownIndex(shownIndex + 1)}
          >
            {getTranslatedTerm('loadMoreButton')}
          </Button>
        </Box>
      )}
    </StyledGroupContainer>
  )
}

CardGroup.propTypes = {
  displayTitle: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default CardGroup
