import React from 'react'
import PropTypes from 'prop-types'
import ContentGroupGatsbyCDAContainer from '@containers/ContentGroup'

const EmbeddedContentGroupContainer = ({ cards, preview, ...rest }) => {
  return (
    <ContentGroupGatsbyCDAContainer items={cards} preview={preview} isEmbedded {...rest} />
  )
}

EmbeddedContentGroupContainer.propTypes = {}

export default EmbeddedContentGroupContainer
