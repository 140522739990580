import React from 'react'
import PropTypes from 'prop-types'
import Box from '@components/Box'
import { A, Button } from '@components/Button'
import { Title, Body } from '@components/RichTextAndTypography'

import Image from '@components/Image'

const Cta = ({ title, appearance, link, excerpt, icon, theme = 'light' }) => {
  theme = theme?.toLowerCase()

  const getCtaLinkColor = theme => {
    switch (theme) {
      case 'dark':
        return 'black'
      case 'light':
        return 'white'
      case 'hero':
        return 'heroColor'
      default:
        return 'white'
    }
  }

  if (appearance === 'Link' || appearance === 'Button') {
    return (
      <Box
        paddingTop={['20px', 5]}
        paddingBottom={2}
        px={2}
        maxWidth={['100%', null, null, null, '75%']}
        textAlign="left"
      >
        {appearance === 'Link' && (
          <A
            color={getCtaLinkColor(theme)}
            hover={getCtaLinkColor(theme)}
            mt={1}
            fontSize={['28px', 9]}
            display="inline-block"
            to={link?.slug}
            href={link?.url}
          >
            {title || 'Learn More'}
          </A>
        )}
        {appearance === 'Button' && (
          <Button
            height="50px"
            color={`${theme}.ctaBtnColor`}
            bg={`${theme}.ctaBtnBg`}
            hoverBg={`${theme}.ctaBtnHover`}
            activeBg={`${theme}.btnPressed`}
            m="0"
            to={link?.slug}
            href={link?.url}
            fontSize={3}
            disabled={false}
          >
            {title || 'Learn More'}
          </Button>
        )}
      </Box>
    )
  }
  return (
    <Box
      border="solid 4px"
      borderColor="blue50"
      my={5}
      py={3}
      px={2}
      textAlign="center"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      {icon && (
        <Box
          mt={3}
          width="60px"
          height="60px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Image src={icon?.file?.url} alt={icon?.description} />
        </Box>
      )}
      <Title
        m="0 auto"
        px={[3, 4, null, null, 0]}
        mt={4}
        maxWidth={['100%', null, null, null, '75%']}
      >
        {link?.title}
      </Title>
      {excerpt && (
        <Body
          lineHeight={2}
          m="0 auto"
          fontWeight={400}
          px={[3, 4, null, null, 0]}
          maxWidth={['100%', null, null, null, '75%']}
          my={3}
        >
          {excerpt}
        </Body>
      )}
      <Box mt={3} mb={4}>
        {appearance === 'Box Link' && (
          <A
            color={theme && [theme].bg}
            hover={theme && [theme].btnHover}
            mt={1}
            display="inline-block"
            to={link?.slug}
            href={link?.url}
          >
            {title || 'Learn More'}
          </A>
        )}
        {appearance === 'Box Button' && (
          <Button
            height="50px"
            color={`${theme}.ctaBtnColor`}
            bg={`${theme}.ctaBtnBg`}
            hoverBg={`${theme}.ctaBtnHover`}
            activeBg={`${theme}.btnPressed`}
            m="0"
            to={link?.slug}
            href={link?.url}
            fontSize={3}
            disabled={false}
          >
            {title || 'Learn More'}
          </Button>
        )}
      </Box>
    </Box>
  )
}

Cta.propTypes = {
  title: PropTypes.string.isRequired,
  appearance: PropTypes.string.isRequired,
  link: PropTypes.object.isRequired,
  theme: PropTypes.oneOf(['Dark', 'Light', 'Hero']),
}

export default Cta
