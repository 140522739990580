import React from 'react'
import PropTypes from 'prop-types'
import Box from '@components/Box'
import ListItem from './ListItem'
import { StyledH2, StyledOL } from './styles'
import { useMobile } from '@hooks/'

const List = ({ layout, items, title, displayTitle, ...rest }) => {
  const [isMobile] = useMobile()
  return (
    <Box width="100%">
      {displayTitle && <StyledH2>{title}</StyledH2>}
      <Box bg="darkestBlack" my={2} height="auto" width="100%" p={2}>
        <StyledOL
          overflowX={isMobile && 'scroll'}
          display="flex"
          flexDirection={isMobile ? 'row' : 'column'}
          p={isMobile && '6px'} //  box shadow padding for overflow mobile
          height="auto"
          bg="darkestBlack"
        >
          {items?.map(({ id, ...itemRest }) => (
            <ListItem
              key={id}
              multiple={items?.length > 1}
              {...rest}
              {...itemRest}
              layout={layout}
            />
          ))}
        </StyledOL>
      </Box>
    </Box>
  )
}

List.propTypes = {
  layout: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  displayTitle: PropTypes.bool,
}

export default List
