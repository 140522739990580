import styled from 'styled-components'
import {
  space,
  position,
  color,
  typography,
  border,
  layout,
  flexbox,
} from 'styled-system'

export const Container = styled.div`
  box-sizing: border-box;
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const TitleContainer = styled(Container)`
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
`
export const StyledAccordionContainer = styled(Container)`
  &:last-child {
    border-bottom-width: 1px;
    border-bottom-style: solid !important;
  }
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`
export const StyledIcon = styled.div`
  transition: all 0.25s ease;
  ${space}
  ${color}
  ${position}
  ${layout}
  ${flexbox}
  ${({ rotate }) => rotate && `transform: rotate(${rotate});`}
`

export const Cell = styled.div`
${space}
${position}
${color}
${typography}
${border}
${layout}
${flexbox}
`

export const Eyebrow = styled.p`
${space}
${color}
${typography}
${border}
${layout}
`
export const H2 = styled.h2`

${space}
${color}
${typography}
${border}
${layout}
margin:0;
`

export const H3 = styled.h3`
${space}
${color}
${typography}
${border}
${layout}
`

export const H4 = styled.h4`
${space}
${color}
${typography}
${border}
${layout}
`
