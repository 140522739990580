import React, { useState, useRef, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import Video from '@components/Video'
import Box from '@components/Box'
import { StyledLightbox, StyledLightboxContainer } from './styles'
import Header from './components/Header'
import Arrows from './components/Arrows'
import Grid from './components/Grid'

const ThumbnailGrid = ({ items, size, lightboxLength, ...rest }) => {
  const [currentIndex, setCurrentIndex] = useState(-1)
  const [isOpen, setIsOpen] = useState(false)
  const lastTarget = useRef()
  const indexRef = useRef(currentIndex)
  const alreadySetFirstElement = useRef(false)
  const startRef = useRef()
  let lightboxIndex = 0

  const gridStyle =
    size === 'Big'
      ? ['100%', '100%', 'repeat(2,1fr)', 'repeat(2,1fr)']
      : size === 'Medium'
      ? ['100%', '100%', 'repeat(3, 1fr)', 'repeat(3, 1fr)']
      : ['100%', '100%', 'repeat(3, 1fr)', 'repeat(4, 1fr)']

  const handleNext = () => {
    if (currentIndex < lightboxLength - 1) {
      indexRef.current = indexRef.current + 1
      setCurrentIndex(currentIndex + 1)
    }
  }
  const handlePrev = () => {
    if (currentIndex !== 0) {
      indexRef.current = indexRef.current - 1
      setCurrentIndex(currentIndex - 1)
    }
  }

  const handleOpen = (index, e) => {
    if (e.currentTarget) lastTarget.current = e.currentTarget
    indexRef.current = index
    startRef.current = index
    setCurrentIndex(index)
    setIsOpen(true)
  }

  const handleClose = () => {
    if (lastTarget?.current) {
      lastTarget.current.focus()
    }
    alreadySetFirstElement.current = false
    setIsOpen(false)
  }

  const handleKeydown = useCallback(
    e => {
      if ((e.key === 'Escape' || e.key === 'Esc') && isOpen) {
        handleClose()
      }

      if (isOpen) {
        // Focus trap with all elements within modal
        if (e.key === 'Tab') {
          // Grab all elements within modal that user can tab through and are visible in UI
          document.addEventListener('keydown', handleKeydown)
          const modalElements = Array.from(
            document.querySelectorAll(
              '.lightbox-left-arrow, .lightbox-right-arrow, .lightbox-close'
            )
          )?.filter(elem => elem.offsetParent)

          const firstElement = modalElements?.[0]

          const lastElement = modalElements[modalElements.length - 1]

          const iframe = document.getElementById('iframe')

          if (
            !e.shiftKey &&
            (e.target === lastElement || e.target === iframe)
          ) {
            e.preventDefault()
            firstElement.focus()
          } else if (e.shiftKey && e.target === firstElement) {
            e.preventDefault()

            lastElement.focus()
          }
        }
      }
    },
    [isOpen]
  )

  useEffect(() => {
    if (isOpen && !alreadySetFirstElement.current) {
      document.addEventListener('keydown', handleKeydown)
      const modalElements = Array.from(
        document.querySelectorAll(
          '.lightbox-left-arrow, .lightbox-right-arrow, .lightbox-close'
        )
      )?.filter(elem => elem.offsetParent)

      const firstElement = modalElements?.[0]

      firstElement.focus()

      alreadySetFirstElement.current = true
    }

    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
  }, [handleKeydown, isOpen])

  // uses forked react-spring-lightbox, takes in image props if type === image. If type === video, uses component.

  let parsedItems = items
    ?.filter(
      item =>
        item?.target?.contentType === 'uiVideo' ||
        item?.target?.contentType === 'uiImage'
    )
    ?.map(({ target }, i) => {
      let tempItem = {
        type: target?.contentType === 'uiVideo' ? 'video' : 'image',
        src:
          target?.contentType === 'uiImage' ? target?.rawSrc?.src : undefined,
        style: { width: '900px' },
      }

      if (tempItem?.type === 'video') {
        tempItem.handleVideoDragClick = () => {}

        tempItem.component = (
          <StyledLightboxContainer
            id={`${startRef?.current} ${i}`}
            width={['500px', '600px', '700px', '800px', '900px', '1000px']}
            height="100%"
            disableFocus={currentIndex !== i}
          >
            <Video
              size="Full Width"
              captions={target?.captions?.file?.url}
              alt={target?.alt}
              src={target?.src}
              externalSource={target?.externalSource}
              muted={startRef?.current === i ? 1 : 0}
              thumbnail={target?.thumbnail}
              videoBehavior={
                target?.externalSource && startRef?.current === i
                  ? 'Autoplay w/o sound'
                  : 'Regular'
              }
              stopPlayer={currentIndex !== i}
              togglePlayOverride={startRef?.current === i}
              muteOverride={false}
            />
          </StyledLightboxContainer>
        )
      }
      return tempItem
    })

  return (
    <>
      <Box
        width="100%"
        display="grid"
        gridColumnGap="16px"
        gridRowGap="16px"
        gridTemplateColumns={gridStyle}
        paddingTop={['32px', '64px']}
        paddingBottom={['32px', '64px']}
      >
        {items?.map(
          ({ thumbnailType, title, targetType, target, ...itemRest }, i) => {
            const haveLightbox =
              targetType === 'uiVideo' || targetType === 'uiImage'
            const thisLightboxIndex = lightboxIndex
            if (haveLightbox) lightboxIndex = lightboxIndex + 1
            return (
              <Grid
                key={title}
                slug={target?.slug}
                href={target?.href}
                targetType={targetType}
                haveLightbox={haveLightbox}
                currentIndex={currentIndex}
                lightboxIndex={thisLightboxIndex}
                handleOpen={handleOpen}
                thumbnailType={thumbnailType}
                {...itemRest}
                {...rest}
              />
            )
          }
        )}
      </Box>

      <StyledLightbox
        bg="rgba(0, 0, 0, .5)"
        zIndex={6000}
        images={parsedItems}
        isOpen={isOpen}
        currentIndex={currentIndex}
        onNext={handleNext}
        onPrev={handlePrev}
        onClose={handleClose}
        renderHeader={() => <Header onClick={handleClose} />}
        renderPrevButton={({ canPrev }) => (
          <Arrows position="left" onClick={handlePrev} disabled={!canPrev} />
        )}
        renderNextButton={({ canNext }) => (
          <Arrows position="right" onClick={handleNext} disabled={!canNext} />
        )}
      />
    </>
  )
}

ThumbnailGrid.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  size: PropTypes.string,
  lightboxLength: PropTypes.number,
}

export default ThumbnailGrid
