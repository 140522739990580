import React from 'react'
import PropTypes from 'prop-types'
import Carousel from '@components/Carousel'
import ThumbnailGrid from '@components/ThumbnailGrid'
import { useMobile } from '@hooks'
import { formatDate, formatTime, parseImage, parseVideo } from '@utils'
import { handleRemoveContentfulFromContentTypeString } from '@utils/dataFormatting'
import Box from '@components/Box'

const shapeSlug = ({ category, slug }, contentType) => {
  switch (contentType) {
    case 'article':
      return category?.slug && slug && `${category?.slug}/${slug}`
    case 'tag':
      return `tag/${slug}`
    case 'person':
      return `people/${slug}`
    default:
      return slug
  }
}

const shapeThumbnailTarget = (item, isMobile) => {
  const sanitizedContentType = handleRemoveContentfulFromContentTypeString(
    item?.contentType || item?.type
  )

  switch (sanitizedContentType) {
    case 'uiImage':
      return {
        ...parseImage(item, {
          orientation: 'landscape',
        }),
        contentType: sanitizedContentType,
        rawSrc: parseImage(item, {
          orientation: 'landscape',
          raw: true,
        }),
      }
    case 'uiVideo':
      return {
        ...parseVideo(item, { isMobile }),
        contentType: sanitizedContentType,
      }
    case 'article':
      return {
        ...item,
        contentType: sanitizedContentType,
        slug: shapeSlug(item, sanitizedContentType),
      }

    case 'tag':
      return {
        ...item,
        contentType: sanitizedContentType,
        slug: shapeSlug(item, sanitizedContentType),
      }
    case 'person':
      return {
        ...item,
        contentType: sanitizedContentType,
        slug: shapeSlug(item, sanitizedContentType),
      }
    case 'externalLink':
      return {
        ...item,
        href: item?.url,
      }
    default:
      return { ...item, contentType: sanitizedContentType }
  }
}

const shapeBasedOnType = ({ item, isMobile }) => {
  const { thumbnail, target, publicationDate, overlayText } = item

  const shapedThumbnail = shapeThumbnailTarget(thumbnail, isMobile)
  const shapedTarget = shapeThumbnailTarget(target, isMobile)

  return {
    ...item,
    publicationDate:
      publicationDate &&
      `${formatDate(publicationDate)}, ${formatTime(publicationDate)}`,
    thumbnail: shapedThumbnail,
    target: shapedTarget,
    targetType: shapedTarget?.contentType,
    thumbnailType: shapedThumbnail?.contentType,
    overlayText: overlayText?.overlayText,
  }
}

const ImageVideoGalleryGatsbyCDAContainer = ({
  items,
  theme = 'light',
  layout,
  isEmbedded,
  ...rest
}) => {
  const [isMobile] = useMobile()
  theme = theme?.toLowerCase()
  items = items?.map(item =>
    shapeBasedOnType({
      item,
      isMobile,
    })
  )

  let lightboxLength = 0

  for (let i = 0; i < items?.length; i++) {
    if (
      items[i].target?.contentType === 'uiImage' ||
      items[i].target?.contentType === 'uiVideo'
    )
      lightboxLength = lightboxLength + 1
  }

  switch (layout) {
    case 'Big Thumbnail Grid':
      return (
        <ThumbnailGrid
          {...rest}
          size="Big"
          items={items}
          lightboxLength={lightboxLength}
        />
      )
    case 'Medium Thumbnail Grid':
      return (
        <ThumbnailGrid
          {...rest}
          size="Medium"
          items={items}
          lightboxLength={lightboxLength}
        />
      )
    case 'Small Thumbnail Grid':
      return (
        <ThumbnailGrid
          {...rest}
          size="Small"
          items={items}
          lightboxLength={lightboxLength}
        />
      )
    case 'Full-Bleed Carousel':
      return (
        <Carousel
          fullBleed
          bottomCaptions
          isEmbedded={isEmbedded}
          {...rest}
          items={items}
          theme={theme}
        />
      )
    case 'Not Full-Bleed Carousel':
      return <Carousel {...rest} items={items} theme={theme} />
    default:
      return <Carousel fullBleed {...rest} items={items} theme={theme} />
  }
}

ImageVideoGalleryGatsbyCDAContainer.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  theme: PropTypes.string,
}

export default ImageVideoGalleryGatsbyCDAContainer
