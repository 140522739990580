import React from 'react'
import PropTypes from 'prop-types'
import { StyledP } from '../styles'
import { useMobile } from '@hooks'
import Box from '@components/Box'

const CarouselCaption = ({
  displayCaptions = false,
  caption = '',
  credit = '',
  publicationDate = '',
  theme = 'light',
}) => {
  const [isMobile] = useMobile()
  if (!displayCaptions || (!credit && !caption && !publicationDate)) return null
  return (
    <Box
      maxHeight="40px"
      width="100%"
      textAlign="start"
      display="flex"
      justifyContent="center"
      mt={2}
    >
      <Box maxWidth={isMobile ? '90%' : '60%'} mx={3}>
        {caption && (
          <StyledP
            display="inline"
            fontSize={1}
            color={`${theme}.peripheralText`}
          >
            {caption}
          </StyledP>
        )}
        {credit && (
          <StyledP
            display="inline"
            color={`${theme}.peripheralText`}
            fontSize={1}
          >
            {`${caption ? ', ' : ''}${credit}`}
          </StyledP>
        )}
        {publicationDate && (
          <StyledP
            display="inline"
            color={`${theme}.peripheralText`}
            fontSize={1}
          >
            {`${credit ? ', ' : ''}${publicationDate}`}
          </StyledP>
        )}
      </Box>
    </Box>
  )
}

CarouselCaption.propTypes = {
  displayBottomCaption: PropTypes.bool,
  caption: PropTypes.string,
  credit: PropTypes.string,
  publicationDate: PropTypes.string,
  theme: PropTypes.string,
}

export default CarouselCaption
