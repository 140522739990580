import React from 'react'
import PropTypes from 'prop-types'
import Image from '@components/Image'
import useMobile from '@hooks/useMobile'
import { parseImage, getAspectRatio } from '@utils'

const ColumnImage = image => {
  const [isMobile] = useMobile()

  const size = image?.size === 'Full' ? 'fullWidth' : 'constrained'
  const orientation = !image?.preview
    ? 'withArtDirection'
    : isMobile
    ? 'landscape'
    : 'portrait'

  const aspectRatio = !image?.preview && getAspectRatio(image, isMobile, size)
  const parsedImage = parseImage(image, {
    orientation,
    size,
    raw: image?.preview,
  })

  return <Image {...parsedImage} size="Large" aspectRatio={aspectRatio} />
}

ColumnImage.propTypes = {}

export default ColumnImage
