import React from 'react'
import PropTypes from 'prop-types'
import List from '@components/List'
import { useTranslatedTerms } from '@hooks'
import { formatDate, formatTime, parseImage } from '@utils'

const ListApolloCPAContainer = ({ items, theme, ...rest }) => {
  const { getTranslatedTerm } = useTranslatedTerms()
  theme = theme?.toLowerCase()

  items = items?.map(
    ({ label, publicationDate, image, type, author, ...rest }) => ({
      ...rest,
      type: type,
      author: {
        ...author,
        slug: author?.slug,
      },
      category: label?.queryName === 'ContentfulCategory' && label?.title,
      publicationDate:
        publicationDate &&
        `${formatDate(publicationDate)}, ${formatTime(publicationDate)}`,
      image: parseImage(image, { orientation: 'landscape' }),
      ctaText:
        type === 'ContentfulArticle'
          ? getTranslatedTerm('ctaArticle')
          : getTranslatedTerm('ctaDefault'),
      external: type === 'ContentfulComponentExternalLink',
    })
  )
  return <List {...rest} items={items} theme={theme} />
}

ListApolloCPAContainer.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  theme: PropTypes.string,
}

export default ListApolloCPAContainer
