import React from 'react'
import PropTypes from 'prop-types'
import { PullQuoteGatsbyCDAContainer } from '@containers/PullQuote'

const EmbeddedPullQuoteContainer = props => {
  return <PullQuoteGatsbyCDAContainer {...props} />
}

EmbeddedPullQuoteContainer.propTypes = {}

export default EmbeddedPullQuoteContainer
