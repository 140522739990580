import React from 'react'
import PropTypes from 'prop-types'
import Video from '@components/Video'
import { useMobile } from '@hooks/'
import { parseVideo } from '@utils'

const VideoGatsbyCDAContainer = video => {
  const [isMobile] = useMobile()

  const parsedVideo = parseVideo(video, { isMobile })

  return <Video {...parsedVideo} />
}

VideoGatsbyCDAContainer.propTypes = {
  cloudinaryVideoSource: PropTypes.array,
  cloudinaryVideoSourcePortrait: PropTypes.array,
}

export default VideoGatsbyCDAContainer
