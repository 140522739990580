import React from 'react'
import PropTypes from 'prop-types'
import Card from '@components/Card'
import { formatDate, formatTime } from '@utils'

const SoundCloudGatsbyCDAContainer = ({ items, theme, ...rest }) => {
  theme = theme?.toLowerCase()
  items = items?.filter(({ linkType }) => linkType === 'Soundcloud')
  items = items.map(({ createdAt, ...restItems }) => ({
    ...rest,
    ...restItems,
    publicationDate:
      createdAt && `${formatDate(createdAt)}, ${formatTime(createdAt)}`,
  }))
  return <Card items={items} theme={theme} cardLayout="Soundcloud" {...rest} />
}

SoundCloudGatsbyCDAContainer.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  theme: PropTypes.string,
}

export default SoundCloudGatsbyCDAContainer
