import styled from 'styled-components'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Image from '@components/Image'

import {
  position,
  flexbox,
  space,
  color,
  layout,
  typography,
  border,
} from 'styled-system'

export const Container = styled.div`
  ${color}
  ${layout}
  ${position}
  ${space}
  ${flexbox}
  ${typography}
  ${border}
`

export const StyledOverlayContainer = styled(Container)``

export const StyledButtonNoFocusContainer = styled(Container)`
  outline: none;
`

export const StyledMediaContainer = styled(Container)``

// overflow: hidden;
export const StyledCarouselContainer = styled(Container)`
  width: 100%;
  ${({ fullBleed }) =>
    fullBleed &&
    `
  width: 100vw;
  left: 50%;
  margin-left: -50vw;`}
  overflow: hidden;
`

export const StyledButtonMaxWidthContainer = styled(Container)``

export const StyledArrowButtonContainer = styled(Container)`
  pointer-events: none;
`

export const StyledP = styled.p`
  margin  :0;
  ${color}
  ${layout}
  ${position}
  ${space}
  ${flexbox}
  ${typography}
`

export const StyledH2 = styled.h2`
  margin: 0;
  ${color}
  ${layout}
  ${position}
  ${space}
  ${flexbox}
  ${typography}
`

export const StyledH3 = styled.h3`
  ${color}
  ${layout}
  ${position}
  ${space}
  ${flexbox}
  ${typography}
`

export const StyledArrowButton = styled(Container)`
  position: absolute;
  top: 25%;
  left: 0;
  right: 0;
  bottom: 0;
  height: 4px;
  width: 35px;
  ${({ buttonColor }) => `background-color: ${buttonColor};`}

  &:hover {
    background-color: ${({ buttonHoverColor }) => buttonHoverColor};
    ::before {
      border-top-color: ${({ buttonHoverColor }) => buttonHoverColor};
      border-right-color: ${({ buttonHoverColor }) => buttonHoverColor};
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: inline-block;
    height: 25px;
    width: 25px;
    border-top: 4px solid ${({ buttonColor }) => buttonColor};
    border-right: 4px solid ${({ buttonColor }) => buttonColor};
    transform:  ${({ direction }) =>
      direction === 'right'
        ? `rotate(45deg) translate(-20%, -40%);`
        : `rotate(-135deg) translate(30%, 30%);`}

`

export const StyledSlide = styled(Container)`
  outline-color: transparent;
`
export const Background = styled.section`
  box-sizing: border-box;
  
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}
`
export const StyledSlider = styled(Slider)`
  .slick-track {
    display: flex;
    align-items: center;
  }
  .slick-list {
    overflow: ${({ fullBleed }) => (!fullBleed ? 'hidden' : 'visible')};
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
    margin: auto;
  }

  .slick-cloned {
    button {
      display: none;
    }
    pointer-events: none;
  }

  //Remove Default Buttons
  .slick-prev:before {
    content: none;
  }
  .slick-next:before {
    content: none;
  }

  //Repositions dots to be inside carousel
  .slick-dots {
    bottom: 2%;
  }

  //Fills dot on active and remove them in mobile view
  .slick-dots li button:before {
    display: ${({ mobileView }) => (mobileView ? 'none' : 'block')};

    color: ${({ dotColor }) => dotColor};
    opacity: 1 !important;
    font-size: 11px;
  }

  .slick-dots li.slick-active button:before {
    display: ${({ mobileView }) => (mobileView ? 'none' : 'block')};

    color: transparent;
    font-size: 10px;
    -webkit-text-stroke: 1px ${({ dotColor }) => dotColor} !important;
  }
`

export const StyledArrow = styled(Container)`
  box-sizing: border-box;
`

export const MediaContainer = styled.div`
  button {
    ${({ disableFocus }) => disableFocus && 'display: none !important;'}
  }
  ${({ crop }) =>
    crop &&
    crop !== 'No Crop' &&
    `
    &::after {
    display: block;
    content: '';
    padding-bottom: ${crop === '1:1' ? '100%' : '56.25%'};
    width: 100%;
  }`}
  
  ${space}
  ${position}
  ${layout}
  ${color}
  ${flexbox}
`

export const StyledArrowContainer = styled(Container)`
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
`

export const StyledButtonWidth = styled(Container)``

export const StyledButtonContainer = styled(Container)`
  transition: height 2s ease-out;
`

export const StyledButton = styled(Container)`
  transform: translateY(-50%);
  cursor: pointer;
  pointer-events: auto;
`

export const StyledPlayContainer = styled.div`
  transform: translate(-50%, -50%);
  cursor: pointer;  
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`
export const StyledPlayButton = styled(Image)`
  padding: 0;
  margin: 0;
  ${space}
  ${color}
  ${typography}
  ${position}
  ${border}
  ${layout}
  ${flexbox}

`
