import React from 'react'
import { StyledPullQuote } from './styles'
import PropTypes from 'prop-types'

const PullQuote = ({ id, text }) => {
  return (
    <StyledPullQuote
      textAlign="center"
      width="100%"
      fontWeight="bold"
      fontSize={[5]}
      p={[3]}
    >
      {text?.text || text}
    </StyledPullQuote>
  )
}

PullQuote.propTypes = {
  text: PropTypes.string,
  id: PropTypes.string,
}

export default PullQuote
