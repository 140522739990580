import React from 'react'
import PropTypes from 'prop-types'
import CardGroup from '@components/CardGroup'
import { useTranslatedTerms } from '@hooks'
import { formatDate, formatTime, parseMedia } from '@utils'

const CardGroupGatsbyCDAContainer = ({ items, theme, ...rest }) => {
  const { getTranslatedTerm } = useTranslatedTerms()

  theme = theme?.toLowerCase()

  items = items?.map(
    ({
      publicationDate,
      image,
      tags,
      type,
      excerpt,
      category,
      aNetworkCompany,
      video,
      name,
      title,
      ...rest
    }) => ({
      ...rest,
      title: name || title,
      type: type,
      tags,
      category: process.env.GATSBY_SPACECAMP && tags ? tags[0] : category,
      publicationDate:
        publicationDate &&
        `${formatDate(publicationDate)}, ${formatTime(publicationDate)}`,
      image: image && parseMedia(image, { orientation: 'landscape' }),
      ctaText:
        type === 'ContentfulArticle' || type === 'blogPost'
          ? getTranslatedTerm('ctaArticle')
          : getTranslatedTerm('ctaDefault'),
      external: type === 'ContentfulComponentExternalLink' || type === 'link',
      authorBy: getTranslatedTerm('byTranslate'),
      excerpt: excerpt?.excerpt || excerpt,
      video: video && parseMedia(video, { orientation: 'landscape' }),
    })
  )

  return <CardGroup {...rest} items={items} theme={theme} cardLayout="Group" />
}

CardGroupGatsbyCDAContainer.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  theme: PropTypes.string,
}

export default CardGroupGatsbyCDAContainer
