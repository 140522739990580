import React from 'react'
import PropTypes from 'prop-types'
import Form from '@components/Form'

const FormGatsbyCDAContainer = ({
  layout,
  formFields,
  formButton,
  ...rest
}) => {
  return (
    <Form
      layout={layout}
      formFields={formFields}
      formButton={formButton}
      {...rest}
    />
  )
}

FormGatsbyCDAContainer.propTypes = {
  layout: PropTypes.string,
  formFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      successMessage: PropTypes.string,
      placeholder: PropTypes.string,
      type: PropTypes.string,
      validation: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  formButton: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      buttonType: PropTypes.string,
      displayText: PropTypes.string,
      navigationLink: PropTypes.object,
    })
  ),
}

export default FormGatsbyCDAContainer
