import React from 'react'
import PropTypes from 'prop-types'
import ModalContent from './ModalContent'
import { Box } from '@components/Box'
import { useDelayedUnmounting, usePortal } from '@hooks/'
import { Overlay } from './styles'
import Portal from './Portal'

const Modal = ({
  content,
  contentFunction,
  children,
  disableClick = false,
  onClose = () => {},
  onOpen = () => {},
  tabIndex,
  disabled,
}) => {
  const { isOpen, openPortal, closePortal, modalRef } = usePortal()
  const shouldRenderChild = useDelayedUnmounting(isOpen, 1500)

  const handleClose = e => {
    closePortal(e)
    onClose()
  }

  const handleOpen = e => {
    openPortal(e, '#modal-close-button')
    onOpen()
  }

  if (disabled) return children

  return (
    <>
      <Box
        id="modal-container"
        tabIndex={tabIndex}
        onClick={!disableClick && handleOpen}
        cursor="pointer"
      >
        {children}
      </Box>
      {shouldRenderChild && (
        <Portal ref={modalRef}>
          <Overlay onClick={handleClose} isMounted={isOpen} />
          <ModalContent isOpen={isOpen} closePortal={handleClose}>
            {content || contentFunction({ modalRef, closePortal })}
          </ModalContent>
        </Portal>
      )}
    </>
  )
}

Modal.propTypes = {
  content: PropTypes.object,
  contentFunction: PropTypes.func,
  children: PropTypes.node,
  disableClick: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  tabIndex: PropTypes.number,
  disabled: PropTypes.bool,
}

export default Modal
