import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@components/Box'
import { useTranslatedTerms, useLockBodyScroll } from '@hooks/'
import Icon from '@components/Icon'
import { debounce } from 'lodash'
import useMediaQuery from '@hooks/useMediaQuery'

const ModalContent = ({ isOpen, closePortal, children }) => {
  const sm = useMediaQuery(`(max-height: 550px)`)
  const md = useMediaQuery(`(max-height: 650px)`)
  const lg = useMediaQuery(`(max-height: 750px)`)
  const xl = useMediaQuery(`(max-height: 875px)`)

  const getPortraitWidth = () => {
    if (sm) return ['80%', '80%', '60%', '50%', '40%', '30%']
    if (md) return ['80%', '80%', '80%', '80%', '60%', '40%']
    if (lg) return ['80%', '80%', '80%', '80%', '80%', '50%']
    if (xl) return ['80%', '80%', '80%', '80%', '80%', '60%']
    return ['80%', '80%', '80%', '80%', '80%', '75%']
  }

  useLockBodyScroll()

  const handleKeydown = (e, func = () => {}) => {
    if (e.key === 'Escape' && isOpen) {
      closePortal()
    }

    if (e.key === 'Enter') {
      func(e)
    }
  }

  return (
    <Box
      position="fixed"
      bg="darkestBlack"
      width={getPortraitWidth()}
      left="50%"
      top="50%"
      p={'16px 28px 28px'}
      zIndex={10000}
      transform="translate(-50%, -50%)"
      fadeIn
      isMounted={isOpen}
    >
      <Box display="flex" justifyContent="space-between" alignContent="center">
        <Box
          color="white"
          tabIndex={0}
          id="modal-close-button"
          role="button"
          onClick={closePortal}
          onKeyDown={e => handleKeydown(e, closePortal)}
          cursor="pointer"
        >
          <Icon glyph="close" height={16} width={16} viewBox="11 11 10 10" />
        </Box>
      </Box>
      <Box display="flex" flexDirection={'column'} alignItems={'center'}>
        <Box my={3} width={'100%'}>
          {children}
        </Box>
      </Box>
    </Box>
  )
}

ModalContent.propTypes = {
  isOpen: PropTypes.bool,
  closePortal: PropTypes.func,
  children: PropTypes.node,
}

export default ModalContent
