import React from 'react'
import PropTypes from 'prop-types'

import EmbeddedEntrySubContainer from './subcontainers'
import { recursivelyRemoveContentfulPrefix } from '@utils/dataFormatting'

const EmbeddedEntryContainer = ({ node, references, ...rest }) => {
  const targetId = node?.data?.target?.sys?.id
  const entry = references?.find(({ id }) => id === targetId)
  const mappedEntry = recursivelyRemoveContentfulPrefix(entry)

  return <EmbeddedEntrySubContainer {...rest} {...mappedEntry} />
}

EmbeddedEntryContainer.propTypes = {}

export default EmbeddedEntryContainer
