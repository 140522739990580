import React from 'react'
import PropTypes from 'prop-types'
import List from '@components/List'
import { formatDate, formatTime, parseImage } from '@utils'
import { useTranslatedTerms } from '@hooks/'

const ListGatsbyCDAContainer = ({ items, theme, ...rest }) => {
  const { getTranslatedTerm } = useTranslatedTerms()
  theme = theme?.toLowerCase()
  items = items?.map(
    ({ label, publicationDate, image, type, excerpt, ...rest }) => ({
      ...rest,
      type: type,
      category: label?.queryName === 'ContentfulCategory' ? label?.title : '',
      publicationDate:
        publicationDate &&
        `${formatDate(publicationDate)}, ${formatTime(publicationDate)}`,
      image: parseImage(image, { orientation: 'landscape' }),
      alt: image?.sourceLandscape?.description || image?.sourceLandscape?.title,
      ctaText:
        type === 'blogPost'
          ? getTranslatedTerm('ctaArticle')
          : getTranslatedTerm('ctaDefault'),
      external: type === 'link',
      authorBy: getTranslatedTerm('byTranslate'),
      excerpt: excerpt?.excerpt || excerpt,
    })
  )
  return <List {...rest} items={items} theme={theme} />
}

ListGatsbyCDAContainer.propTypes = {}

export default ListGatsbyCDAContainer
