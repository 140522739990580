import React from 'react'
import PropTypes from 'prop-types'
import TableAccordionItem from './TableAccordionItem'
import { Container } from './style'

const TableAccordion = ({
  rowEntry = [],
  rowHeaders = [],
  isOpen = false,
  theme = 'light',
}) => {
  return (
    <Container display="flex" flexWrap="wrap" fontFamily="headings">
      {rowHeaders?.map((t, i) => (
        <TableAccordionItem
          key={`${t} + ${i}`}
          isOpen={isOpen}
          tableData={rowEntry?.[t]}
          title={t}
          theme={theme}
        />
      ))}
    </Container>
  )
}

TableAccordion.propTypes = {
  theme: PropTypes.string,
  isOpen: PropTypes.bool,
  rowHeader: PropTypes.arrayOf(PropTypes.string),
  rowEntry: PropTypes.objectOf(PropTypes.array),
}

export default TableAccordion
