import React from 'react'
import PropTypes from 'prop-types'
import TableAccordion from './TableAccordion'
import { Container, TitleContainer, H2 } from './style'

const TableAccordionBlock = ({ tables = [], title = '', theme = 'light' }) => {
  return (
    <Container width={[1]} my={[5]} fontFamily="headings">
      <TitleContainer mb={4}>
        <H2 ml={0} fontWeight="regular" fontSize={[3]} color="magenta">
          {title}
        </H2>
      </TitleContainer>
      {tables?.map((t, i) => (
        <TableAccordion
          key={`${t?.id} + ${i}`}
          theme={theme}
          initialState={i === 0}
          {...t}
        />
      ))}
    </Container>
  )
}

TableAccordionBlock.propTypes = {
  tables: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      tableRows: PropTypes.arrayOf(PropTypes.object),
    })
  ),
  title: PropTypes.string,
  theme: PropTypes.string,
}

export default TableAccordionBlock
