import React from 'react'
import PropTypes from 'prop-types'

const ImageApolloCPAContainer = props => {
  return <div>Image ApolloCPA</div>
}

ImageApolloCPAContainer.propTypes = {}

export default ImageApolloCPAContainer
