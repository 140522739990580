import React from 'react'
import PropTypes from 'prop-types'
import { Container, Cell, H4 } from './style'

const TableAccordionItem = ({
  tableData = [],
  title = '',
  isOpen = false,
  theme = 'light',
}) => {
  return (
    <Container
      width={[1, 1 / 2, 1 / 2, 1 / 5]}
      my={[2, 2, 2, 3]}
      p="0 20px 0 0"
      display={isOpen ? 'block' : 'none'}
    >
      <H4 fontSize={3} color={`${theme}.text`}>
        {title}
      </H4>
      {tableData?.map((t, i) => (
        <Cell
          key={`${t} + ${i}`}
          fontSize={1}
          py={2}
          color={`${theme}.textAlt`}
        >
          {t}
        </Cell>
      ))}
    </Container>
  )
}

TableAccordionItem.propTypes = {
  title: PropTypes.string,
  tableData: PropTypes.arrayOf(PropTypes.string),
  isOpen: PropTypes.bool,
}

export default TableAccordionItem
