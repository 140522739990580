import React, { useRef, useEffect, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { StyledP, StyledH3, StyledOverlayContainer } from '../styles'
import { trunc } from '@utils/'
import Box from '@components/Box'
import { debounce } from 'lodash'

const CarouselOverlay = ({ title, overlayText, theme }) => {
  const overlayRef = useRef()
  const [area, setArea] = useState(0)
  const truncSize = area < 32000 ? 80 : area < 70000 ? 150 : 300

  const handleResize = useCallback(() => {
    if (overlayRef.current) {
      setArea(
        overlayRef?.current?.clientHeight * overlayRef?.current?.clientWidth
      )
    }
  }, [])

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', debounce(handleResize, 500))

    return window.removeEventListener('resize', debounce(handleResize, 500))
  }, [handleResize])

  return (
    <StyledOverlayContainer
      position="absolute"
      height="30%"
      left="0"
      right="0"
      bottom="0"
      bg="rgba(0, 0, 0, 0.7)"
      display="flex"
      justifyContent="center"
      ref={overlayRef}
    >
      <Box
        maxWidth={[
          'maxWidth.xsmall',
          'maxWidth.small',
          'maxWidth.medium',
          'maxWidth.large',
          'maxWidth.xlarge',
          'maxWidth.xxlarge',
        ]}
        width="100%"
        height="100%"
        color={`${theme}.text`}
        display="flex"
        flexDirection="column"
        textAlign="start"
        mt={2}
      >
        {title && (
          <StyledH3
            fontSize={[1, 2, 3]}
            width="fit-content"
            fontWeight={600}
            color={`${theme}.textInvert`}
            mt={0}
            mb={3}
          >
            {title}
          </StyledH3>
        )}
        {overlayText && (
          <StyledP fontSize={[1, 1, 2]} color={`${theme}.textInvert`}>
            {trunc(overlayText, truncSize)}
          </StyledP>
        )}
      </Box>
    </StyledOverlayContainer>
  )
}

CarouselOverlay.propTypes = {
  title: PropTypes.string,
  overlayText: PropTypes.string,
  theme: PropTypes.string,
}

export default CarouselOverlay
