import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Box from '@components/Box'
import Icon from '@components/Icon'
import { StyledArrowContainer } from '../styles'
import { useHover } from '@hooks/'
import { ThemeContext } from 'styled-components'

const Arrows = ({ position, onClick, disabled, theme = 'light' }) => {
  const [hoverRef, isHovered] = useHover()
  const themePackage = useContext(ThemeContext)
  const buttonColor = themePackage?.colors?.[theme]?.carouselButton
  const buttonHoverColor = themePackage?.colors?.[theme]?.carouselButtonHover

  return (
    <StyledArrowContainer width="100%" ref={hoverRef}>
      <Box
        role="button"
        tabIndex={0}
        className={
          position === 'left' ? 'lightbox-left-arrow' : 'lightbox-right-arrow'
        }
        onClick={onClick}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            onClick()
          }
        }}
        position="absolute"
        zIndex="200"
        left={position === 'left' ? 0 : 'unset'}
        right={position === 'right' ? 0 : 'unset'}
      >
        {!disabled && position === 'left' && (
          <Icon
            viewBox="-2 -2 12 12"
            height={36}
            width={36}
            margin="0"
            glyph="left-caret"
            fill={isHovered ? buttonHoverColor : 'black'}
          />
        )}
        {!disabled && position === 'right' && (
          <Icon
            viewBox="-2 -2 12 12"
            height={36}
            width={36}
            margin="0"
            glyph="right-caret"
            fill={isHovered ? buttonHoverColor : 'black'}
          />
        )}
      </Box>
    </StyledArrowContainer>
  )
}

Arrows.propTypes = {
  position: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  theme: PropTypes.string,
}

export default Arrows
