import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import usePreviewQuery, {
  GET_ENTRIES_BY_CONTENT_TYPE_ID,
} from '@hooks/usePreviewQuery'
import { recursivelyGetLinkedEntriesAndSpreadFields } from '@utils/'
import { ImageVideoGalleryApolloCPAContainer } from '@containers/ImageVideoGallery'
import { useLocale } from '@hooks/'

const ContentGroupImageVideoGalleryApolloCPAContainer = ({
  items,
  ...rest
}) => {
  const [galleryItems, setGalleryItems] = useState([])
  const { locale } = useLocale()
  const { loading, error, data } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID,
    id: 'componentSlideThumbnail', // imagevideogallery
    locale,
    limit: 350,
  })

  useEffect(() => {
    if (!loading && !error && data) {
      const entries = data?.entries?.includes?.Entry
      const assets = data?.entries?.includes?.Asset
      const tempItems = items?.map(item =>
        recursivelyGetLinkedEntriesAndSpreadFields(item, entries, assets)
      )

      setGalleryItems(tempItems)
    }
  }, [data, error, items, loading])

  return <ImageVideoGalleryApolloCPAContainer {...rest} items={galleryItems} />
}

ContentGroupImageVideoGalleryApolloCPAContainer.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
}

export default ContentGroupImageVideoGalleryApolloCPAContainer
