import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Box from '@components/Box'
import { StyledH3, StyledP, StyledTraditionalContainer } from '../../styles'
import useMediaQuery from '@hooks/useMediaQuery'
import { ThemeContext } from 'styled-components'

const TableTraditionalRow = ({
  rowEntry = [],
  rowHeaders = [],
  theme = 'light',
}) => {
  const themePackage = useContext(ThemeContext)
  const breakpoint = themePackage?.breakpoints

  const singleDisplayBreak = useMediaQuery(`(max-width: ${breakpoint?.[0]})`)

  return (
    <Box display="flex" flexDirection="row" flexWrap="wrap" mb={3}>
      {rowHeaders.map((header, i) => (
        <StyledTraditionalContainer
          key={`${header}${i}`}
          pb={singleDisplayBreak ? 0 : 2}
          mb={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="start"
          pr={3}
          width={['100%', '50%', '33%', '33%', '33%']}
        >
          <Box width="100%" pb={4}>
            <StyledH3 m={0} fontSize={5} mb={3} color={`${theme}.text`}>
              {header}
            </StyledH3>
            {rowEntry?.[header]?.map((data, i) => (
              <StyledP
                m={0}
                mb={2}
                fontWeight={200}
                fontSize={3}
                key={`${data}${i}`}
                color={`${theme}.text`}
              >
                {data}
              </StyledP>
            ))}
          </Box>
        </StyledTraditionalContainer>
      ))}
    </Box>
  )
}

TableTraditionalRow.propTypes = {
  theme: PropTypes.string,
  rowHeader: PropTypes.arrayOf(PropTypes.string),
  rowEntry: PropTypes.objectOf(PropTypes.array),
}

export default TableTraditionalRow
