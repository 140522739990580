import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import usePreviewQuery, {
  GET_ENTRIES_BY_CONTENT_TYPE_ID_AND_IDS_AND_TAG,
  GET_ENTRIES_BY_CONTENT_TYPE_ID_AND_IDS_AND_CATEGORY,
  GET_ENTRIES_BY_CONTENT_TYPE_ID_AND_IDS_AND_AUTHOR,
  GET_ENTRIES_BY_CONTENT_TYPE_ID_AND_IDS,
} from '@hooks/usePreviewQuery'

import { CardGroupApolloCPAContainer } from '@containers/CardGroup'
import { SoundCloudApolloCPAContainer } from '@containers/SoundCloud'
import { ListApolloCPAContainer } from '@containers/List'
import { recursivelyGetLinkedEntriesAndSpreadFields } from '@utils/'
import { useLocale } from '@hooks'
import Box from '@components/Box'

const ManualContentGroupApolloCPAContainer = ({
  itemIds,
  locale = 'en-US',
  layout,
  limit,
  displayDateAndShare = false,
  displayTitle,
  ...fields
}) => {
  const {
    loading: loadingBlogPost,
    error: errorBlogPost,
    data: dataBlogPost,
  } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID_AND_IDS,
    skipBool: itemIds.length === 0,
    ids: itemIds.join(','),
    id: 'article',
    locale,
  })

  const {
    loading: loadingExternalLink,
    error: errorExternalLink,
    data: dataExternalLink,
  } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID_AND_IDS,
    skipBool: itemIds.length === 0,
    ids: itemIds.join(','),
    id: 'componentExternalLink',
    locale,
  })
  const {
    loading: loadingPage,
    error: errorPage,
    data: dataPage,
  } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID_AND_IDS,
    skipBool: itemIds.length === 0,
    ids: itemIds.join(','),
    id: 'page',
    locale,
  })
  const {
    loading: loadingPersonOrTeamMember,
    error: errorPersonOrTeamMember,
    data: dataPersonOrTeamMember,
  } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID_AND_IDS,
    skipBool: itemIds.length === 0,
    ids: itemIds.join(','),
    id: 'person',
    locale,
  })
  const [items, setItems] = useState([])

  const getLinkedEntry = (arr, targetId) => {
    return arr.find(({ sys: { id } }) => id === targetId)
  }

  const mapItemsToPage = useCallback(
    (items, entryIncludes, assetIncludes) => {
      let tempItems = itemIds
        ?.map(id => getLinkedEntry(items, id))
        ?.map(
          itemInfo =>
            (tempItems = recursivelyGetLinkedEntriesAndSpreadFields(
              {
                ...itemInfo?.fields,
                id: itemInfo?.sys.id,
                type: itemInfo?.sys?.contentType?.sys?.id,
              },
              entryIncludes,
              assetIncludes
            ))
        )

      tempItems = tempItems?.map(tempItem => ({
        ...tempItem,
        link:
          tempItem?.type === 'componentExternalLink'
            ? tempItem?.url
            : tempItem?.slug,
      }))

      return tempItems
    },
    [itemIds]
  )

  useEffect(() => {
    if (
      !loadingBlogPost &&
      !errorBlogPost &&
      dataBlogPost &&
      !loadingExternalLink &&
      !errorExternalLink &&
      dataExternalLink &&
      !loadingPage &&
      !errorPage &&
      dataPage &&
      !loadingPersonOrTeamMember &&
      !errorPersonOrTeamMember &&
      dataPersonOrTeamMember
    ) {
      let items = [
        ...dataBlogPost?.entries?.items,
        ...dataExternalLink?.entries?.items,
        ...dataPage?.entries?.items,
        ...dataPersonOrTeamMember?.entries?.items,
      ]

      let allIncludes = [
        dataBlogPost?.entries?.includes,
        dataExternalLink?.entries?.includes,
        dataPage?.entries?.includes,
        dataPersonOrTeamMember?.entries?.includes,
      ]

      let nestedEntries = []
      let nestedAssets = []

      //  only added Entry and Asset if the content type have nested entries
      for (let i = 0; i < allIncludes.length; i++) {
        if (allIncludes[i]?.Entry) {
          nestedEntries.push(...allIncludes[i]?.Entry)
        }
        if (allIncludes[i]?.Asset) {
          nestedAssets.push(...allIncludes[i]?.Asset)
        }
      }

      let mappedItems = mapItemsToPage(items, nestedEntries, nestedAssets)
      setItems(mappedItems)
    }
  }, [
    loadingBlogPost,
    errorBlogPost,
    dataBlogPost,
    loadingExternalLink,
    errorExternalLink,
    dataExternalLink,
    loadingPage,
    errorPage,
    dataPage,
    loadingPersonOrTeamMember,
    errorPersonOrTeamMember,
    dataPersonOrTeamMember,
    mapItemsToPage,
  ])
  const limitedManualItems = items.slice(0, limit)

  const handleRenderSlicedLayoutItems = (items, numberOfColumnsPerRow) => {
    let incrementalAmount = numberOfColumnsPerRow
    let nodesArr = []
    let tempStartIndex = 0

    for (let i = 0; i < items.length; i++) {
      let tempEndIndex = tempStartIndex + incrementalAmount
      if (tempEndIndex <= items.length) {
        // numberColumnsPerRow goes in evenly to Items.length
        nodesArr.push(
          <CardGroupApolloCPAContainer
            {...fields}
            displayTitle={i === 0 ? displayTitle : false}
            items={items.slice(tempStartIndex, tempEndIndex)}
            noBody
          />
        )
        tempStartIndex = tempEndIndex
      } else {
        nodesArr.push(
          <CardGroupApolloCPAContainer
            {...fields}
            displayTitle={i === 0 ? displayTitle : false}
            items={items.slice(tempStartIndex, tempEndIndex)}
            noBody
          />
        )
        return nodesArr
      }
    }
    return nodesArr
  }

  switch (layout) {
    case '50:50':
      return (
        <Box
          mt={3}
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
        >
          {handleRenderSlicedLayoutItems(items, 2)}
        </Box>
      )
    case '33:33:33':
      return (
        <Box
          mt={3}
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
        >
          {handleRenderSlicedLayoutItems(items, 3)}
        </Box>
      )
    case '25:25:25:25':
      return (
        <Box
          mt={3}
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
        >
          {handleRenderSlicedLayoutItems(items, 4)}
        </Box>
      )
    case 'Automatic Formatting Based On Amount of Cards Added':
      return (
        <CardGroupApolloCPAContainer
          {...fields}
          displayTitle={displayTitle}
          items={limitedManualItems}
        />
      )
    case 'Card Group - Full':
      return (
        <CardGroupApolloCPAContainer
          {...fields}
          displayTitle={displayTitle}
          items={limitedManualItems}
        />
      )
    case 'Card Group - Minimal':
      return (
        <CardGroupApolloCPAContainer
          {...fields}
          displayTitle={displayTitle}
          items={limitedManualItems}
          noBody
        />
      )
    case 'Card Group - No Media':
      return (
        <CardGroupApolloCPAContainer
          {...fields}
          displayTitle={displayTitle}
          items={limitedManualItems}
          noMedia
        />
      )
    case 'Card Grid - Full Bleed Image':
      return null

    case 'Soundcloud':
      return (
        <SoundCloudApolloCPAContainer
          {...fields}
          displayTitle={displayTitle}
          items={limitedManualItems}
        />
      )
    case 'List - Full':
      return (
        <ListApolloCPAContainer
          {...fields}
          displayTitle={displayTitle}
          layout={layout}
          items={items}
        />
      )
    case 'List - Minimal':
      return (
        <ListApolloCPAContainer
          {...fields}
          displayTitle={displayTitle}
          layout={layout}
          items={items}
        />
      )
    default:
      return null
  }
}

const AutomaticContentGroupApolloCPAContainer = ({
  id,
  locale = 'en-US',
  layout = '',
  type,
  limit,
  displayDateAndShare = false,
  ...fields
}) => {
  const switchQueryBasedOnType = type => {
    return type === 'tag'
      ? GET_ENTRIES_BY_CONTENT_TYPE_ID_AND_IDS_AND_TAG
      : type === 'category'
      ? GET_ENTRIES_BY_CONTENT_TYPE_ID_AND_IDS_AND_CATEGORY
      : GET_ENTRIES_BY_CONTENT_TYPE_ID_AND_IDS_AND_AUTHOR
  }
  const {
    loading: loadingAuto,
    error: errorAuto,
    data: dataAuto,
  } = usePreviewQuery({
    query: switchQueryBasedOnType(type),
    skipBool: !id,
    ids: id,
    id: 'article',
    locale,
    limit,
  })

  const [items, setItems] = useState([])

  const mapItemsToPage = useCallback((items, entryIncludes, assetIncludes) => {
    let tempItems = items?.map(({ fields, sys }) =>
      recursivelyGetLinkedEntriesAndSpreadFields(
        { ...fields, id: sys?.id, type: sys?.contentType?.sys?.id },
        entryIncludes,
        assetIncludes
      )
    )

    tempItems = tempItems?.map(tempItem => ({
      ...tempItem,
      link:
        tempItem?.type === 'componentExternalLink'
          ? tempItem?.url
          : tempItem?.slug,
    }))

    return tempItems
  }, [])

  useEffect(() => {
    if (!loadingAuto && !errorAuto && dataAuto) {
      let items = dataAuto?.entries?.items || []
      let mappedItems = mapItemsToPage(
        items,
        dataAuto?.entries?.includes?.Entry,
        dataAuto?.entries?.includes?.Asset
      )

      mappedItems = mappedItems.filter(item => !item?.isPasswordProtected)

      setItems(mappedItems)
    }
  }, [loadingAuto, errorAuto, dataAuto, id, mapItemsToPage, limit])

  const handleRenderSlicedLayoutItems = (items, numberOfColumnsPerRow) => {
    let incrementalAmount = numberOfColumnsPerRow
    let nodesArr = []
    let tempStartIndex = 0

    for (let i = 0; i < items.length; i++) {
      let tempEndIndex = tempStartIndex + incrementalAmount
      if (tempEndIndex <= items.length) {
        // numberColumnsPerRow goes in evenly to Items.length
        nodesArr.push(
          <CardGroupApolloCPAContainer
            key={`${items?.title || 'cards'}${i}`}
            {...fields}
            items={items.slice(tempStartIndex, tempEndIndex)}
            noBody
          />
        )
        tempStartIndex = tempEndIndex
      } else {
        nodesArr.push(
          <CardGroupApolloCPAContainer
            key={`${items?.title || 'cards'}${i}`}
            {...fields}
            items={items.slice(tempStartIndex, tempEndIndex)}
            noBody
          />
        )
        return nodesArr
      }
    }
    return nodesArr
  }

  switch (layout) {
    case 'Automatic Formatting Based On Amount of Cards Added':
      return <CardGroupApolloCPAContainer {...fields} items={items} />
    case '50:50':
      return (
        <Box
          mt={3}
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
        >
          {handleRenderSlicedLayoutItems(items, 2)}
        </Box>
      )
    case '33:33:33':
      return (
        <Box
          mt={3}
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
        >
          {handleRenderSlicedLayoutItems(items, 3)}
        </Box>
      )
    case '25:25:25:25':
      return (
        <Box
          mt={3}
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
        >
          {handleRenderSlicedLayoutItems(items, 4)}
        </Box>
      )
    case 'Card Group - Full':
      return <CardGroupApolloCPAContainer {...fields} items={items} />
    case 'Card Group - Minimal':
      return <CardGroupApolloCPAContainer {...fields} items={items} noBody />
    case 'Card Group - No Media':
      return <CardGroupApolloCPAContainer {...fields} items={items} noMedia />
    case 'Card Grid - Full Bleed Image':
      return null
    case 'Soundcloud':
      return <SoundCloudApolloCPAContainer {...fields} items={items} />
    case 'List - Full':
      return (
        <ListApolloCPAContainer {...fields} layout={layout} items={items} />
      )
    case 'List - Minimal':
      return (
        <ListApolloCPAContainer {...fields} layout={layout} items={items} />
      )
    default:
      return null
  }
}

const ContentGroupApolloCPAContainer = ({
  type = 'manualCardGroup',
  items,
  numberOfItemsFromFeedToPull = 6,
  layout,
  ...rest
}) => {
  const { locale } = useLocale()
  if (type === 'automaticCardGroup') {
    return (
      <AutomaticContentGroupApolloCPAContainer
        id={items?.source?.id}
        type={items?.source?.type}
        title={items?.source?.title}
        limit={numberOfItemsFromFeedToPull}
        locale={locale}
        layout={layout}
        {...rest}
      />
    )
  } else {
    let itemIds = items?.items
      ? items.items.map(({ sys }) => sys.id)
      : items
      ? items.map(({ id }) => id)
      : null
    return (
      <ManualContentGroupApolloCPAContainer
        itemIds={itemIds}
        limit={numberOfItemsFromFeedToPull || 24}
        locale={locale}
        layout={layout}
        {...rest}
      />
    )
  }
}
ContentGroupApolloCPAContainer.propTypes = {
  type: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  numberOfItemsFromFeedToPull: PropTypes.number,
}

export default ContentGroupApolloCPAContainer
