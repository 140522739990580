import React, { useContext } from 'react'
import {
  StyledArrowButton,
  StyledButton,
  StyledButtonNoFocusContainer,
} from '../styles'
import PropTypes from 'prop-types'
import { ThemeContext } from 'styled-components'

const CarouselSlickBtn = ({
  direction = 'right',
  onClick = null,
  theme = 'light',
}) => {
  //  need theme package to pass into styled components, not styled system
  const themePackage = useContext(ThemeContext)
  const handleEnterKey = e => {
    if (e.key === 'Enter') {
      onClick()
    }
  }
  const buttonColor = themePackage?.colors?.[theme]?.carouselButton
  const buttonHoverColor = themePackage?.colors?.[theme]?.carouselButtonHover
  return (
    <StyledButton
      role="button"
      name={`${direction} carousel button`}
      zIndex={1}
      onClick={onClick}
      onKeyDown={e => handleEnterKey(e)}
      tabIndex="0"
      p={1}
      width="35px"
    >
      <StyledButtonNoFocusContainer position="relative" tabIndex="-1">
        <StyledArrowButton
          direction={direction}
          buttonColor={buttonColor}
          buttonHoverColor={buttonHoverColor}
        />
      </StyledButtonNoFocusContainer>
    </StyledButton>
  )
}

CarouselSlickBtn.propTypes = {
  onClick: PropTypes.func,
  direction: PropTypes.string,
  theme: PropTypes.string,
}

export default CarouselSlickBtn
