import React from 'react'
import PropTypes from 'prop-types'
import ImageVideoGalleryGatsbyCDAContainer from '@containers/ImageVideoGallery'
import { useLocale } from '@hooks/'

const ContentGroupImageVideoGalleryGatsbyCDAContainer = ({
  items,
  preview = false,
  ...rest
}) => {
  return <ImageVideoGalleryGatsbyCDAContainer items={items} {...rest} />
}

ContentGroupImageVideoGalleryGatsbyCDAContainer.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  preview: PropTypes.bool,
}

export default ContentGroupImageVideoGalleryGatsbyCDAContainer
