import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Box from '@components/Box'
import { StyledH3, StyledP, StyledStatsItemContainer } from '../../styles'
import useMediaQuery from '@hooks/useMediaQuery'
import { ThemeContext } from 'styled-components'

const TableStatsRow = ({
  rowEntry = [],
  rowHeaders = [],
  theme = 'light',
  ...rest
}) => {
  const themePackage = useContext(ThemeContext)
  const breakpoint = themePackage?.breakpoints

  const singleDisplayBreak = useMediaQuery(`(max-width: ${breakpoint?.[0]})`)
  const doubleDisplayBreak = useMediaQuery(`(max-width: ${breakpoint?.[1]})`)
  const borderBreakpointStyles = singleDisplayBreak
    ? 'single'
    : doubleDisplayBreak
    ? 'double'
    : 'multiple'

  const columnLen = rowHeaders.length
  const borderWidth = '1px'
  const colWidthFull = `calc(${100 / columnLen}% - ${borderWidth} * 3)`
  const colWidthDouble = `calc(50% - ${borderWidth})`
  return (
    <Box display="flex" flexDirection="row" flexWrap="wrap" my={3}>
      {rowHeaders.map((header, i) => (
        <StyledStatsItemContainer
          key={`${header}${i}`}
          pt={2}
          pb={singleDisplayBreak ? 0 : 2}
          my={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="start"
          borderColor={process.env.GATSBY_SPACECAMP ? 'black' : 'grey60'}
          borderRightWidth={borderWidth}
          borderRightStyle="solid"
          width={['100%', colWidthDouble, colWidthFull, colWidthFull]}
          borderBreakpointStyles={borderBreakpointStyles}
        >
          <Box
            textAlign="center"
            borderColor={process.env.GATSBY_SPACECAMP ? 'black' : 'grey60'}
            borderBottomWidth={singleDisplayBreak ? borderWidth : '0'}
            borderBottomStyle={singleDisplayBreak ? 'solid' : 'none'}
            width={singleDisplayBreak ? '70%' : '100%'}
            pb={4}
          >
            <StyledH3 m={0} fontSize={[8, 10]} color={`${theme}.text`}>
              {header}
            </StyledH3>
            {rowEntry?.[header]?.map((data, i) => (
              <StyledP
                m={0}
                fontWeight={200}
                color={`${theme}.text`}
                fontSize={[3, 6]}
                key={`${data}${i}`}
              >
                {data}
              </StyledP>
            ))}
          </Box>
        </StyledStatsItemContainer>
      ))}
    </Box>
  )
}

TableStatsRow.propTypes = {
  rowHeader: PropTypes.arrayOf(PropTypes.string),
  rowEntry: PropTypes.objectOf(PropTypes.array),
  theme: PropTypes.string,
}

export default TableStatsRow
