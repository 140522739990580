import React from 'react'
import PropTypes from 'prop-types'
import Box from '@components/Box'
import SoundCloudItem from './SoundCloudItem'
import { useMobile } from '@hooks'

const SoundCloud = ({
  title = '',
  displayTitle = true,
  items = [{}],
  theme = '',
  ...rest
}) => {
  const [isMobile] = useMobile()
  return (
    <Box width="100%" display="flex" flexDirection="column" flexWrap="no-wrap">
      {items?.map(({ id, ...itemRest }, i) => (
        <SoundCloudItem
          key={`${id}+${i}`}
          isMobile={isMobile}
          label={'Podcast'}
          theme={theme}
          {...itemRest}
        />
      ))}
    </Box>
  )
}
SoundCloud.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  displayTitle: PropTypes.bool,
  theme: PropTypes.string,
}

export default SoundCloud
