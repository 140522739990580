import React, { useRef, useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Body } from '@components/RichTextAndTypography'
import Box from '@components/Box'
import { Logo } from '@components/Button'
import { trunc } from '@utils'
import { StyledP, StyledContainer } from '../styles'
import { useMobile } from '@hooks/'
import { stripEscapeChars } from '@utils/dataFormatting'

const SimpleBody = ({
  theme,
  link,
  external,
  title,
  excerpt,
  hideExcerpt,
  desktopFontSize,
  name,
}) => {
  const [isMobile] = useMobile()
  const [truncSize, setTruncSize] = useState(200)
  const textBoxRef = useRef(null)

  const getTruncSize = useCallback(() => {
    if (!textBoxRef) return null
    const textBoxSize = textBoxRef?.current?.clientWidth
    setTruncSize(
      isMobile ? 150 : textBoxSize < 200 ? 100 : textBoxSize < 300 ? 150 : 200
    )
  }, [isMobile])

  useEffect(() => {
    getTruncSize()
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', getTruncSize)
    }
    return function cleanup() {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', getTruncSize)
      }
    }
  }, [getTruncSize])

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Logo
          fontSize={['25px', desktopFontSize]}
          fontWeight={600}
          theme={theme}
          lineHeight={isMobile && '30px'}
          to={!external && link}
          href={external && link}
        >
          {name && <StyledP mb={2}> {name} </StyledP>}
          <StyledP clamplines={5}>{stripEscapeChars(title)}</StyledP>
        </Logo>
        {!hideExcerpt && excerpt && (
          <StyledContainer ref={textBoxRef} mt={2} flex={1}>
            {excerpt && (
              <Body theme={theme} fontSize={1} lineHeight={2}>
                {trunc(excerpt, truncSize)}
              </Body>
            )}
          </StyledContainer>
        )}
      </Box>
    </>
  )
}

SimpleBody.propTypes = {
  category: PropTypes.object,
  title: PropTypes.string,
  theme: PropTypes.string,
  link: PropTypes.string,
  excerpt: PropTypes.string,
  hideExcerpt: PropTypes.bool,
  desktopFontSize: PropTypes.string,
}

export default SimpleBody
