import React from 'react'
import PropTypes from 'prop-types'
import PullQuote from '@components/PullQuote'

const PullQuoteGatsbyCDAContainer = props => {
  return <PullQuote {...props} />
}

PullQuoteGatsbyCDAContainer.propTypes = {}

export default PullQuoteGatsbyCDAContainer
