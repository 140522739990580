import React from 'react'
import PropTypes from 'prop-types'
import { VideoGatsbyCDAContainer } from '@containers/Video'

const EmbeddedVideoContainer = props => {
  return <VideoGatsbyCDAContainer {...props} />
}

EmbeddedVideoContainer.propTypes = {
  thumbnail: PropTypes.object,
}

export default EmbeddedVideoContainer
