import React from 'react'
import PropTypes from 'prop-types'
import { StyledTitle } from '../../styles'
import Box from '@components/Box'
import TableTraditional from './TableTraditional'

const TableStatsBlock = ({
  title = '',
  tables = [],
  theme = 'light',
  ...rest
}) => {
  return (
    <Box>
      {title && (
        <StyledTitle
          m={0}
          mb={3}
          fontWeight={300}
          fontSize={3}
          color={`${theme}.heroAltLight`}
        >
          {title}
        </StyledTitle>
      )}
      <Box width="100%">
        {tables.map(({ id, ...table }) => (
          <TableTraditional key={id} theme={theme} {...table} />
        ))}
      </Box>
    </Box>
  )
}

TableStatsBlock.propTypes = {
  tables: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      tableRows: PropTypes.arrayOf(PropTypes.object),
    })
  ),
  title: PropTypes.string,
  theme: PropTypes.string,
}

export default TableStatsBlock
