import React from 'react'
import PropTypes from 'prop-types'
import Box from '@components/Box'
import Card from '@components/Card'
import { useMobile } from '../../hooks'
import { StyledCardsContainer, StyledCardWidthContainer } from './styles'

const CardGroupMapping = ({
  items,
  gridOverride,
  remainderGridOverride = '',
  ...rest
}) => {
  const [isMobile] = useMobile()

  const itemsLength = items.length

  const modFourRemainder = itemsLength % 4
  const modThreeRemainder = itemsLength % 3

  let remainder = 0
  let gridLayout = ''

  if (gridOverride) {
    gridLayout = gridOverride
  } else if (modFourRemainder >= 2 || modFourRemainder === 0) {
    remainder = modFourRemainder
    gridLayout = `repeat(4, minmax(130px ,1fr))`
  } else {
    remainder = modThreeRemainder
    gridLayout = `repeat(3, minmax(130px ,1fr))`
  }

  const remainderGridLayout =
    remainderGridOverride || `repeat(${remainder}, minmax(130px ,1fr))`

  return (
    <Box width="100%" display="flex" flexDirection="column">
      {remainder !== 0 && (
        <StyledCardsContainer
          position="relative"
          display="grid"
          gridTemplateColumns={!isMobile ? remainderGridLayout : '100%'}
          fullWidth={isMobile}
          gridColumnGap="16px"
        >
          {items?.slice(0, remainder)?.map(({ id, ...itemRest }) => (
            <StyledCardWidthContainer flexShrink={0} key={id} mt={4}>
              <Card {...itemRest} {...rest} />
            </StyledCardWidthContainer>
          ))}
        </StyledCardsContainer>
      )}
      {itemsLength - remainder !== 0 && (
        <StyledCardsContainer
          position="relative"
          display="grid"
          gridTemplateColumns={!isMobile ? gridLayout : '100%'}
          fullWidth={isMobile}
          gridColumnGap="16px"
        >
          {items
            ?.slice(remainder, itemsLength + 1)
            ?.map(({ id, ...itemRest }) => (
              <StyledCardWidthContainer flexShrink={0} key={id} mt={4}>
                <Card
                  desktopFontSize={
                    !process.env.GATSBY_SPACECAMP
                      ? 3
                      : modFourRemainder >= 2 || modFourRemainder === 0
                      ? '24px'
                      : '35px'
                  }
                  {...itemRest}
                  {...rest}
                />
              </StyledCardWidthContainer>
            ))}
        </StyledCardsContainer>
      )}
    </Box>
  )
}

CardGroupMapping.propTypes = {}

export default CardGroupMapping
