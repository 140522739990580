import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  MediaContainer,
  StyledPlayContainer,
  StyledPlayButton,
} from '../styles'
import CarouselOverlay from './CarouselOverlay'
import Image from '@components/Image'
import Video from '@components/Video'
import Box from '@components/Box'
import { LinkWrapper } from '@components/Button'

import { useMobile } from '@hooks/'
import { Modal } from '@components/Modal'
import playBtn from '@components/Video/assets/Playbutton.png'

const CarouselMedia = ({
  targetType,
  targetSlug,
  targetExternalSource,
  targetSource = {},
  targetVideoThumbnail = {},
  targetAlt,
  targetHref,
  targetVideoCaptions,
  thumbnailVideoThumbnail = {},
  thumbnailType,
  thumbnailExternalSource,
  thumbnailSource = {},
  thumbnailAlt,
  theme = 'light',
  title,
  displayOverlayText = false,
  overlayText = '',
  currSlide = 0,
  slideIndex = 0,
  alt = '',
  crop = 'No Crop',
  ...rest
}) => {
  const [showOverlay, setShowOverlay] = useState(true)
  const [isMobile] = useMobile()
  const disableFocus = currSlide !== slideIndex
  const hasModal = targetType === 'uiImage' || targetType === 'uiVideo'

  const aspectRatio = crop === '1:1' ? 1 / 1 : crop === '16:9' ? 16 / 9 : ''
  const truncSize = isMobile ? 120 : 300

  const toggleOverlay = () => {
    if (showOverlay === true) {
      setShowOverlay(false)
    }
  }

  const modalContent = modal => (
    <Box>
      {targetType === 'uiImage' && (
        <Image alt={targetAlt} size="Large" src={targetSource} />
      )}
      {targetType === 'uiVideo' && (
        <Video
          modal={modal}
          captions={targetVideoCaptions}
          src={targetSource}
          externalSource={targetExternalSource}
          notInteractable
          thumbnail={targetVideoThumbnail}
          onClick={() => toggleOverlay()}
          size="Full Width"
          videoBehavior="Autoplay w/o sound"
          togglePlayOverride
          muteOverride={false}
        />
      )}
    </Box>
  )

  return (
    <LinkWrapper
      to={targetSlug}
      href={targetHref}
      disabled={hasModal}
      tabIndex={disableFocus ? -1 : 0}
    >
      <Modal
        contentFunction={modalContent}
        tabIndex={disableFocus ? -1 : 0}
        disabled={!hasModal}
      >
        <MediaContainer
          display="flex"
          minWidth="100px"
          justifyContent="center"
          alignItems="center"
          position="relative"
          width="100%"
          height="100%"
          bg={!thumbnailSource && 'grey'}
          disableFocus={disableFocus}
          crop={crop}
        >
          <Box
            position={aspectRatio && 'absolute'}
            display="flex"
            alignItems="center"
            top="0"
            bottom="0"
            right="0"
            left="0"
            width="100%"
          >
            {thumbnailType === 'uiImage' && (
              <Image
                size="Large"
                src={thumbnailSource}
                aspectRatio={aspectRatio}
              />
            )}
            {thumbnailType === 'uiVideo' && (
              <Video
                alt={alt}
                src={thumbnailSource}
                externalSource={thumbnailExternalSource}
                thumbnail={thumbnailVideoThumbnail}
                mute={1}
                onClick={() => toggleOverlay()}
                cursor={(targetSlug || targetHref) && 'pointer'}
                size="Full Width"
                videoBehavior={'Looping w/o sound'}
                stopPlayer={disableFocus}
                videoFit="Cover"
                height="100%"
                notInteractable
              />
            )}
          </Box>
          {targetType === 'uiVideo' && (
            <StyledPlayContainer position="absolute" top="50%" left="50%">
              <StyledPlayButton
                src={playBtn}
                alt="play-button"
              ></StyledPlayButton>
            </StyledPlayContainer>
          )}
          {displayOverlayText && (
            <CarouselOverlay
              title={title}
              overlayText={overlayText}
              theme={theme}
              truncSize={truncSize}
            />
          )}
        </MediaContainer>
      </Modal>
    </LinkWrapper>
  )
}

CarouselMedia.propTypes = {
  targetType: PropTypes.string,
  targetSlug: PropTypes.string,
  targetSource: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  targetVideoThumbnail: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  targetAlt: PropTypes.string,
  targetHref: PropTypes.string,
  targetVideoCaptions: PropTypes.object,
  thumbnailVideoThumbnail: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  thumbnailType: PropTypes.string,
  thumbnailSource: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  thumbnailAlt: PropTypes.string,
  theme: PropTypes.string,
  title: PropTypes.string,
  displayOverlayText: PropTypes.bool,
  overlayText: PropTypes.string,
  currSlide: PropTypes.number,
  slideIndex: PropTypes.number,
  alt: PropTypes.string,
}

export default CarouselMedia
