import React from 'react'
import { IconWrapper, ToolTip } from '../styles'
import { ShareDarkModeOffIcon, ShareDarkModeOnIcon } from './ControlIcons'
import { useLightMode, useCopyToClipboard } from '@hooks'

let baseUrl =
  process.env.NODE_ENV === 'development' ? 'localhost:8000' : 'www.a.network'
const ShareButton = ({
  shortUrl = '',
  external = false,
  color,
  m,
  alignCenter = false,
}) => {
  const [hasCopied, copyToClipboardOnClick] = useCopyToClipboard(
    external ? shortUrl : `https://${baseUrl}/${shortUrl}`
  )
  const [isLightMode] = useLightMode()
  const Icon = isLightMode ? ShareDarkModeOnIcon : ShareDarkModeOffIcon

  return (
    <IconWrapper
      mode={isLightMode}
      onClick={copyToClipboardOnClick}
      tabIndex={-1}
      m={m}
    >
      <Icon fill={color} />
      <ToolTip
        mode={isLightMode}
        hasCopied={hasCopied}
        alignCenter={alignCenter}
      >
        Copied
      </ToolTip>
    </IconWrapper>
  )
}

export default ShareButton
