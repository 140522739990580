import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import EmbeddedEntryGatsbyCDAContainer from './EmbeddedEntryGatsbyCDAContainer'
import EmbeddedEntryApolloCPAContainer from './EmbeddedEntryApolloCPAContainer'
import { PreviewContext } from '@context/'

const EmbeddedEntryContainer = props => {
  const preview = useContext(PreviewContext)

  return preview ? (
    <EmbeddedEntryApolloCPAContainer {...props} />
  ) : (
    <EmbeddedEntryGatsbyCDAContainer {...props} />
  )
}

EmbeddedEntryContainer.propTypes = {}

export default EmbeddedEntryContainer
