import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useMobile } from '@hooks'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Background, StyledCarouselContainer, StyledH2 } from './styles'
import CarouselSlide from './components/CarouselSlide'
import Box from '@components/Box'
import CarouselButtons from './components/CarouselButtons'

const Carousel = ({
  items = [],
  title = '',
  theme = 'light',
  displayTitle = false,
  fullBleed,
  isEmbedded = false,
  ...rest
}) => {
  const [isMobile] = useMobile()
  const [currSlide, setCurrSlide] = useState(0)
  const slider = useRef(null)
  let swiping = false
  const carouselRef = useRef(null)

  const currentSlide = e => {
    setCurrSlide(e)
  }
  const carouselSettings = {
    dots: false,
    arrows: false,
    mobileView: isMobile,
    afterChange: currentSlide,
    // adaptiveHeight: true,
  }

  const handleMouseDown = event => {
    swiping = true
    event.preventDefault()
  }

  const handleMouseUp = () => {
    swiping = slider.current.innerSlider.state.swiping
  }

  const handleClick = event => {
    if (swiping) {
      event.preventDefault()
      event.stopPropagation()
      event.nativeEvent.preventDefault()
      event.nativeEvent.stopPropagation()
    }
  }

  // removes tabindex on cloned slides and non-current slide video controls for running videos
  useEffect(() => {
    const handleVideoRemoveFocus = el => {
      el.setAttribute('data-tabindex', el.getAttribute('tabindex'))
      el.setAttribute('tabindex', '-1')
    }
    const handleVideoFocus = el => {
      el.setAttribute('tabindex', el.getAttribute('data-tabindex'))
      el.removeAttribute('data-tabindex')
    }

    carouselRef.current
      .querySelectorAll(
        '.slick-cloned a, .slick-cloned button, .slick-cloned div, .slick-cloned #modal-container'
      )
      .forEach(el => {
        el.setAttribute('tabindex', '-1')
      })

    carouselRef.current
      .querySelectorAll(
        '.no-focus:not(.slick-cloned) .vjs-control-bar div[tabindex]:not([data-tabindex])'
      )
      .forEach(el => handleVideoRemoveFocus(el))
    carouselRef.current
      .querySelectorAll(
        '.focusable:not(.slick-cloned) .vjs-control-bar div[data-tabindex]'
      )
      .forEach(el => handleVideoFocus(el))
  }, [currSlide])

  return (
    <Box
      width="100%"
      my={isEmbedded && 4}
      mt={!isEmbedded && displayTitle && 5}
    >
      {displayTitle && (
        <StyledH2
          textAlign="left"
          fontSize={5}
          fontWeight={600}
          color={`${theme}.text`}
          margin="0 auto"
          mb={4}
        >
          {title}
        </StyledH2>
      )}
      <StyledCarouselContainer
        ref={carouselRef}
        fullBleed={fullBleed}
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        className="gallery"
      >
        <Background
          width="inherit"
          data-testid="carousel-default"
          textAlign="center"
          height="fit-content"
          position="relative"
        >
          {items && items.length >= 2 && (
            <CarouselButtons slider={slider} theme={theme} direction="left" />
          )}
          <CarouselSlide
            fullBleed={fullBleed}
            currSlide={currSlide}
            ref={slider}
            theme={theme}
            items={items}
            carouselSettings={carouselSettings}
            clickCaptureHandler={handleClick}
            mouseUpCaptureHandler={handleMouseUp}
            mouseDownCaptureHandler={handleMouseDown}
            {...rest}
          />
          {items && items.length >= 2 && (
            <CarouselButtons slider={slider} theme={theme} direction="right" />
          )}
        </Background>
      </StyledCarouselContainer>
    </Box>
  )
}

Carousel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  theme: PropTypes.string,
  displayTitle: PropTypes.bool,
  displayDateAndShare: PropTypes.bool,
}

export default Carousel
