import React from 'react'
import PropTypes from 'prop-types'
import Cta from '@components/Cta'
import { useLocale } from '@hooks/'

const getSlug = ({ slug, type, localeSlug, category, id, preview }) => {
  if (preview) return `${localeSlug}/${slug}` // for preview embed
  switch (type) {
    case 'article':
      return `${category?.slug}/${slug}`
    case 'person':
      return `people/${slug}`
    case 'tag':
      return `tag/${slug || id}`
    case 'category':
    case 'page':
    default:
      return slug
  }
}

const CtaGatsbyCDAContainer = ({ link, preview, ...rest }) => {
  const { localeSlug } = useLocale()

  link = {
    ...link,
    slug:
      link?.slug &&
      getSlug({
        ...link,
        preview,
        localeSlug,
        type: link?.contentType || link?.type,
      }),
  }

  return <Cta link={link} {...rest} />
}

CtaGatsbyCDAContainer.propTypes = {}

export default CtaGatsbyCDAContainer
