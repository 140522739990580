import React from 'react'
import PropTypes from 'prop-types'
import Video from '@components/Video'
import Box from '@components/Box'
import Image from '@components/Image'
import {
  StyledPlayContainer,
  StyledImageContainer,
  StyledPlayButton,
  MediaContainer,
} from '../styles'
import playBtn from '@components/Video/assets/Playbutton.png'

import { LinkWrapper } from '@components/Button'

const Grid = ({
  thumbnailType,
  targetType,
  thumbnail,
  haveLightbox,
  lightboxIndex,
  handleOpen,
  slug,
  href,
  crop = 'No Crop',
}) => {
  const onClickHandler = e => {
    handleOpen(lightboxIndex, e)
  }

  const onKeyDownHandler = e => {
    if (e.key === 'Enter') handleOpen(lightboxIndex, e)
  }

  const aspectRatio = crop === '1:1' ? 1 / 1 : crop === '16:9' ? 16 / 9 : ''

  return (
    <StyledImageContainer
      onClick={haveLightbox ? onClickHandler : undefined}
      onKeyDown={haveLightbox ? onKeyDownHandler : undefined}
      tabIndex={haveLightbox ? 0 : -1}
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
      cursor={haveLightbox ? 'pointer' : 'default'}
    >
      <LinkWrapper
        display="block"
        width="100%"
        height="100%"
        top={0}
        bottom={0}
        left={0}
        right={0}
        to={slug}
        href={href}
        disabled={haveLightbox}
      >
        <MediaContainer height="100%" width="100%" crop={crop}>
          <Box
            position={aspectRatio && 'absolute'}
            display="flex"
            alignItems="center"
            top="0"
            bottom="0"
            right="0"
            left="0"
            width="100%"
          >
            {thumbnailType === 'uiImage' ? (
              <Image
                src={thumbnail?.src}
                size="Large"
                width="100%"
                aspectRatio={aspectRatio}
              />
            ) : (
              <Video
                size="Full Width"
                alt={thumbnail?.alt}
                src={thumbnail?.src}
                externalSource={thumbnail?.externalSource}
                thumbnail={thumbnail?.thumbnail}
                cursor={'pointer'}
                videoBehavior="Looping w/o sound"
                height="100%"
                videoFit="Cover"
                notInteractable
              />
            )}
          </Box>
        </MediaContainer>
      </LinkWrapper>
      {targetType === 'uiVideo' && (
        <StyledPlayContainer position="absolute" top="50%" left="50%">
          <StyledPlayButton src={playBtn} alt="play-button"></StyledPlayButton>{' '}
        </StyledPlayContainer>
      )}
    </StyledImageContainer>
  )
}

Grid.propTypes = {
  thumbnailType: PropTypes.string,
  targetType: PropTypes.string,
  thumbnail: PropTypes.object,
  haveLightbox: PropTypes.bool,
  lightboxIndex: PropTypes.number,
  handleOpen: PropTypes.func,
  slug: PropTypes.string,
  href: PropTypes.string,
}

export default Grid
