import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import usePreviewQuery, {
  GET_ENTRIES_BY_CONTENT_TYPE_ID,
} from '@hooks/usePreviewQuery'
import { recursivelyGetLinkedEntriesAndSpreadFields } from '@utils/'
import { ImageVideoGalleryApolloCPAContainer } from '@containers/ImageVideoGallery'
import { useLocale } from '@hooks/'
import TwoColumn from '@components/TwoColumn'

const ModuleTwoColumnApolloCPAContainer = ({ columns, ...rest }) => {
  const [parsedColumns, setParsedColumns] = useState([])
  const { locale } = useLocale()
  const { loading, error, data } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID,
    id: 'moduleTwoColumns', // imagevideogallery
    locale,
  })

  useEffect(() => {
    if (!loading && !error && data) {
      const entries = data?.entries?.includes?.Entry
      const assets = data?.entries?.includes?.Asset
      const parsedColumns = recursivelyGetLinkedEntriesAndSpreadFields(
        columns,
        entries,
        assets
      )
      setParsedColumns(parsedColumns)
    }
  }, [columns, data, error, loading])

  return <TwoColumn columns={parsedColumns} {...rest} preview />
}

ModuleTwoColumnApolloCPAContainer.propTypes = {}

export default ModuleTwoColumnApolloCPAContainer
