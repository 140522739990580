import React from 'react'
import PropTypes from 'prop-types'
import { camelize } from '@utils/'
import InputText from './InputText'
import InputTextArea from './InputTextArea'
import InputRadio from './InputRadio'
import InputCheckbox from './InputCheckbox'
import InputSelect from './InputSelect'
import Box from '@components/Box'
import { layouts, validations } from '../utils'

const Contact = ({ theme, layout, fields }) => {
  const colA = fields?.filter(field => field?.type !== 'Radio')
  const colB = fields?.filter(field => field?.type === 'Radio')

  return (
    <Box
      display="grid"
      gridTemplateColumns={[null, null, null, '1fr']}
      gridColumnGap="2rem"
    >
      <Box>
        {colA?.map(field => (
          <Input key={field?.id} theme={theme} layout={layout} field={field} />
        ))}
      </Box>
      <Box>
        {colB?.map(field => (
          <Input key={field?.id} theme={theme} layout={layout} field={field} />
        ))}
      </Box>
    </Box>
  )
}

const Footer = ({ theme, layout, fields }) => {
  return fields?.map(field => (
    <Input key={field?.id} theme={theme} layout={layout} field={field} />
  ))
}

const Default = ({ theme, layout, fields }) => {
  return fields?.map(field => (
    <Input key={field?.id} theme={theme} layout={layout} field={field} />
  ))
}

const Input = ({ theme, layout, field }) => {
  const fragment = {
    theme: theme,
    layout: layout,
    label: field?.title,
    name: camelize(field?.title),
    isRequired: field?.validation?.includes(validations.REQUIRED),
  }

  switch (field?.type) {
    case 'TextArea':
      return <InputTextArea {...fragment} placeholder={field?.placeholder} />
    case 'Radio':
      return <InputRadio {...fragment} options={field?.options} />
    case 'Checkbox':
      return <InputCheckbox {...fragment} options={field?.options} />
    case 'Dropdown':
      return (
        <InputSelect
          {...fragment}
          options={field?.options}
          placeholder={field?.placeholder}
        />
      )
    default:
      return (
        <InputText
          {...fragment}
          type={field?.type?.toLowerCase()}
          placeholder={field?.placeholder}
        />
      )
  }
}

const Inputs = ({ theme, layout, fields }) => {
  switch (layout) {
    case layouts.CONTACT:
      return <Contact theme={theme} layout={layout} fields={fields} />
    case layouts.FOOTER:
      return <Footer theme={theme} layout={layout} fields={fields} />
    case layouts.SUBSCRIBE:
    default:
      return <Default theme={theme} layout={layout} fields={fields} />
  }
}

Inputs.propTypes = {
  theme: PropTypes.string,
  layout: PropTypes.string,
  field: PropTypes.object,
}

export default Inputs
