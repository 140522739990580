import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@components/Button'
import Box from '@components/Box'
import { layouts } from '../utils'
import css from '@styled-system/css'

export const buttonTypes = {
  SUBMISSION: 'Submission',
  REDIRECT: 'Redirect',
}

const FormButton = ({ button, loading, error, isSubmitting, ...styling }) => {
  switch (button?.buttonType) {
    case buttonTypes.SUBMISSION:
      return (
        <Button
          disable={isSubmitting}
          type="submit"
          m="0"
          display="inline"
          {...styling}
        >
          {!loading && error && 'Please try again'}
          {!loading && !error && (button?.displayText || 'Submit')}
          {loading && !error && 'Received, thanks!'}
        </Button>
      )
    case buttonTypes.REDIRECT:
      return (
        <Button
          disable={isSubmitting}
          type="submit"
          m="0"
          display="inline"
          {...styling}
        >
          {button?.displayText || 'Submit'}
        </Button>
      )
    default:
      return null
  }
}

const Buttons = ({ theme, layout, button, loading, error, isSubmitting }) => {
  switch (layout) {
    case layouts.CONTACT:
      return (
        <Box>
          <FormButton
            key={button?.id}
            button={button}
            loading={loading}
            error={error}
            isSubmitting={isSubmitting}
            color={`${theme}.ctaBtnColor`}
            bg={`${theme}.ctaBtnBg`}
            css={css({
              textTransform: 'uppercase',
              letterSpacing: '0',
              '&:hover': {
                bg: `${theme}.ctaBtnHover`,
                color: `${theme}.ctaBtnColor`,
              },
            })}
            fontSize={5}
            height="59px"
            minWidth="249px"
            textTransform="uppercase"
          />
        </Box>
      )
    case layouts.FOOTER:
      return (
        <Box display="inline-block" verticalAlign="top">
          <FormButton
            color={`${theme}.ctaBtnColor`}
            bg={`${theme}.ctaBtnBg`}
            css={css({
              textTransform: 'uppercase',
              letterSpacing: '0',
              '&:hover': {
                bg: `${theme}.ctaBtnHover`,
                color: `${theme}.ctaBtnColor`,
              },
            })}
            key={button?.id}
            button={button}
            loading={loading}
            error={error}
            isSubmitting={isSubmitting}
            minHeight="36px"
          />
        </Box>
      )
    case layouts.SUBSCRIBE:
    default:
      return (
        <Box>
          <FormButton
            color={`${theme}.ctaBtnColor`}
            bg={`${theme}.ctaBtnBg`}
            css={css({
              textTransform: 'uppercase',
              letterSpacing: '0',
              '&:hover': {
                bg: `${theme}.ctaBtnHover`,
                color: `${theme}.ctaBtnColor`,
              },
            })}
            key={button?.id}
            button={button}
            loading={loading}
            error={error}
            isSubmitting={isSubmitting}
          />
        </Box>
      )
  }
}

Buttons.propTypes = {
  theme: PropTypes.string,
  layout: PropTypes.string,
  button: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  isSubmitting: PropTypes.bool,
}

export default Buttons
