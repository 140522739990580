import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'

const Portal = ({ children }, ref) => {
  useEffect(() => {
    const modalNode = ref.current
    return () => {
      if (modalNode) {
        document.body.removeChild(modalNode)
      }
      ref.current = null
    }
  }, [ref])

  if (!ref.current) {
    ref.current = document.createElement('div')
    document.body.appendChild(ref.current)
  }
  return ReactDOM.createPortal(children, ref.current)
}

export default React.forwardRef(Portal)
