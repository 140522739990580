import styled from 'styled-components'
import {
  space,
  position,
  color,
  typography,
  border,
  layout,
  flexbox,
  variant,
  grid,
} from 'styled-system'

export const Container = styled.div`
  ${color}
  ${layout}
  ${position}
  ${space}
  ${flexbox}
  ${typography}
  ${border}
  ${grid}
`

export const StyledGroupContainer = styled(Container)`
  overflow-anchor: none;
`

export const StyledCardsContainer = styled(Container)`
  box-sizing: border-box;
`

export const StyledCardWidthContainer = styled(Container)`
  box-sizing: border-box;
`
