import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Box from '@components/Box'
import Icon from '@components/Icon'
import { StyledClose } from '../styles'
import { useHover } from '@hooks/'
import { ThemeContext } from 'styled-components'

const Header = ({ onClick, theme = 'light' }) => {
  const [hoverRef, isHovered] = useHover()
  const themePackage = useContext(ThemeContext)
  const buttonColor = themePackage?.colors?.[theme]?.carouselButton
  const buttonHoverColor = themePackage?.colors?.[theme]?.carouselButtonHover
  return (
    <Box
      width="100%"
      height="100px"
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      zIndex={6000}
      position="absolute"
      pointerEvents="none"
    >
      <StyledClose
        role="button"
        onClick={onClick}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            onClick()
          }
        }}
        className="lightbox-close"
        ref={hoverRef}
        tabIndex={0}
        margin="0 30px 0 0"
      >
        <Icon
          glyph="close"
          height={32}
          width={32}
          viewBox="8 8 16.03 14.8"
          fill={isHovered ? buttonHoverColor : 'black'}
        />
      </StyledClose>
    </Box>
  )
}

Header.propTypes = {
  onClick: PropTypes.func,
  theme: PropTypes.string,
}

export default Header
