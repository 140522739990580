import styled from 'styled-components'

import {
  position,
  flexbox,
  space,
  color,
  layout,
  typography,
  border,
} from 'styled-system'

export const Container = styled.div`
  ${color}
  ${layout}
  ${position}
  ${space}
  ${flexbox}
  ${typography}
  ${border}
  & iframe {
    width: 100% !important;
  }
`

export const StyledYtContainer = styled(Container)`
  & iframe {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
  }
`

export const StyledContainer = styled(Container)``

export const StyledH2 = styled.h2`
  ${color}
  ${layout}
  ${position}
  ${space}
  ${flexbox}
  ${typography}
  ${border}
`
