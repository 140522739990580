import React from 'react'
import PropTypes from 'prop-types'
import Box from '@components/Box'
import { Label, Field, Error } from './Input'
import { camelize, hexToRgba } from '@utils/'
import { components } from 'react-select'
import { StyledSelect } from '../styles.js'
import theme from '@theme/'
/* eslint-disable react/display-name */

const Select = ({ options, placeholder, field, form: { setFieldValue } }) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      outline: 'none',
      borderRadius: '0',
      border:
        state.menuIsOpen || state.isFocused
          ? `1px solid ${hexToRgba(theme.colors.darkestBlack, 1)}`
          : `1px solid ${hexToRgba(theme.colors.darkestBlack, 0)}`,
      borderBottom:
        state.menuIsOpen &&
        `1px solid ${hexToRgba(theme.colors.darkestBlack, 0)}`,
      backgroundColor: state.isFocused
        ? theme.colors.white
        : theme.colors.white,
      boxShadow: 'none',
      '&:hover': {
        borderBottom:
          state.isFocused &&
          !state.menuIsOpen &&
          `1px solid ${hexToRgba(theme.colors.darkestBlack, 1)}`,
      },
      transition: 'all 0.2s',
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color: theme.colors.darkestBlack,
      '&:hover': { color: theme.colors.darkestBlack },
    }),
    menu: provided => ({
      ...provided,
      borderRadius: '0',
      marginTop: '-1px',
      boxShadow: 'none',
      border: `1px solid ${hexToRgba(theme.colors.darkestBlack, 1)}`,
      borderTop: 'none',
    }),
    menuList: provided => ({
      ...provided,
      paddingTop: '0',
    }),
    option: (provided, state) => ({
      ...provided,
      color: theme.colors.darkestBlack,
      backgroundColor:
        (state.isSelected && theme.colors.greyLight) ||
        (state.isFocused && theme.colors.grey60),
    }),
  }

  return (
    <StyledSelect
      options={options}
      styles={customStyles}
      value={
        options ? options.find(option => option.value === field.value) : ''
      }
      onChange={option => setFieldValue(field.name, option.value)}
      placeholder={placeholder}
      components={{
        Menu: props => <components.Menu {...props} className="menu" />,
      }}
    />
  )
}

const InputSelect = ({
  theme,
  layout,
  label,
  name,
  options,
  isRequired,
  placeholder,
}) => {
  return (
    <Box>
      <Label theme={theme} htmlFor={name} mb={2}>
        {label}
        {isRequired && '*'}
      </Label>
      <Field
        theme={theme}
        name={name}
        isRequired={isRequired}
        placeholder={placeholder}
        component={Select}
        options={options.map(option => ({
          value: camelize(option),
          label: option,
        }))}
      />
      <Error theme={theme} name={name} />
    </Box>
  )
}

InputSelect.propTypes = {
  theme: PropTypes.string,
  layout: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  isRequired: PropTypes.bool,
}

export default InputSelect
