import React from 'react'
import PropTypes from 'prop-types'
import Box from '@components/Box'
import { StyledP } from '../styles'

const ColumnList = ({ items }) => {
  const itemLength = items?.length
  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Box display="flex" flexDirection="column">
        {items?.map((item, i) => (
          <Box
            minHeight="100px"
            display="flex"
            alignItems="center"
            p={3}
            mb={i < itemLength - 1 ? 3 : 0}
            bg="greyLight"
            key={`${item}${i}`}
          >
            <StyledP fontSize={5} fontWeight={600}>
              {item}
            </StyledP>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

ColumnList.propTypes = {}

export default ColumnList
