import React from 'react'
import PropTypes from 'prop-types'
import { StyledSlider, StyledSlide } from '../styles'
import CarouselCaption from './CarouselCaption'
import CarouselMedia from './CarouselMedia'

const CarouselSlide = (
  {
    items,
    carouselSettings = {},
    theme = 'light',
    currSlide,
    fullBleed,
    clickCaptureHandler,
    mouseUpCaptureHandler,
    mouseDownCaptureHandler,
    ...rest
  },
  ref
) => {
  return (
    <StyledSlider ref={ref} fullBleed={fullBleed} {...carouselSettings}>
      {items &&
        items?.map(({ title, target, thumbnail, ...restItem }, i) => (
          <StyledSlide
            key={title || `slide+${i}`}
            onClickCapture={clickCaptureHandler}
            onClick={clickCaptureHandler}
            onMouseUpCapture={mouseUpCaptureHandler}
            onMouseDownCapture={mouseDownCaptureHandler}
          >
            <CarouselMedia
              title={title}
              targetType={target?.contentType}
              targetSlug={target?.slug}
              targetSource={target?.src}
              targetExternalSource={target?.externalSource}
              targetVideoThumbnail={target?.thumbnail}
              targetAlt={target?.alt}
              targetHref={target?.href}
              targetVideoCaptions={target?.captions?.file?.url}
              thumbnailVideoThumbnail={thumbnail?.thumbnail}
              thumbnailSource={thumbnail?.src}
              thumbnailExternalSource={thumbnail?.externalSource}
              thumbnailType={thumbnail?.contentType}
              thumbnailAlt={thumbnail?.alt}
              slideIndex={i}
              currSlide={currSlide}
              {...restItem}
              {...rest}
            />
            <CarouselCaption theme={theme} {...rest} />
          </StyledSlide>
        ))}
    </StyledSlider>
  )
}

export default React.forwardRef(CarouselSlide)
