import React from 'react'
import PropTypes from 'prop-types'
import ImageGatsbyCDAContainer from '@containers/Image'
import { useMobile } from '@hooks/'
import { parseImage, getAspectRatio } from '@utils'

const EmbeddedImageContainer = image => {
  const [isMobile] = useMobile()

  const size = image?.size === 'Full' ? 'fullWidth' : 'constrained'
  const orientation = !image?.preview
    ? 'withArtDirection'
    : isMobile
    ? 'portrait'
    : 'landscape'

  const parsedImage = parseImage(image, {
    orientation,
    size,
    raw: image?.preview,
  })

  return <ImageGatsbyCDAContainer {...parsedImage} />
}

EmbeddedImageContainer.propTypes = {
  sourceLandscape: PropTypes.object,
  sourcePortrait: PropTypes.object,
}

export default EmbeddedImageContainer
