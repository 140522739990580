import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import TableAccordionRow from './TableAccordionRow'
import {
  StyledAccordionContainer,
  StyledIcon,
  TitleContainer,
  H4,
} from './style'
import { ThemeContext } from 'styled-components'
import Icon from '@components/Icon'

const TableAccordion = ({
  tableRows = [],
  initialState = false,
  title = '',
  theme = 'light',
}) => {
  const [isOpen, setOpen] = useState(initialState)
  const handleClick = () => {
    setOpen(!isOpen)
  }
  const themePackage = useContext(ThemeContext)
  const buttonColor = themePackage?.colors?.[theme]?.btnInvert
  return (
    <StyledAccordionContainer
      borderTopWidth="1px"
      borderTopStyle="solid"
      borderColor={`${theme}.borderAlt`}
    >
      <TitleContainer
        width={1}
        display="flex"
        justifyContent="space-between"
        onClick={handleClick}
        aria-expanded={isOpen}
        cursor="pointer"
        borderBottomWidth="1px"
        borderBottomStyle={isOpen ? 'solid' : 'none'}
        borderColor={`${theme}.borderAlt`}
      >
        <H4 fontSize={3} color={`${theme}.text`}>
          {title}
        </H4>
        <StyledIcon
          display="flex"
          justifyContent="center"
          alignItems="center"
          rotate={isOpen && '-180deg'}
        >
          <Icon
            fill={buttonColor}
            width="30px"
            height="30px"
            glyph="accordion-arrow"
          />
        </StyledIcon>
      </TitleContainer>
      {tableRows?.map(({ id, ...rest }, i) => (
        <TableAccordionRow
          key={id}
          sectionTitle={title}
          initialActive={i === 0}
          isOpen={isOpen}
          theme={theme}
          {...rest}
        />
      ))}
    </StyledAccordionContainer>
  )
}

TableAccordion.propTypes = {
  tableRows: PropTypes.arrayOf(
    PropTypes.shape({
      rowHeaders: PropTypes.arrayOf(PropTypes.string),
      rowEntry: PropTypes.objectOf(PropTypes.array),
    })
  ),
  initialState: PropTypes.bool,
  title: PropTypes.string,
  theme: PropTypes.string,
}

export default TableAccordion
