import React from 'react'
import PropTypes from 'prop-types'
import { TableGatsbyCDAContainer } from '@containers/Table'

const EmbeddedTableGatsbyCDAContainer = ({ ...rest }) => {
  return <TableGatsbyCDAContainer {...rest} />
}

EmbeddedTableGatsbyCDAContainer.propTypes = {}

export default EmbeddedTableGatsbyCDAContainer
