import React from 'react'
import PropTypes from 'prop-types'
import Box from '@components/Box'

import { StyledP, StyledRightArrow, StyledShare } from '../styles'
import { ShareButton } from '@components/Controls'

import { Logo, LabelButton } from '@components/Button'

const Cta = ({
  callToActionAppearance = '',
  callToActionText = '',
  ctaLink = '',
  external = false,
  theme = 'light',
}) => {
  switch (callToActionAppearance) {
    case 'Link':
      return (
        <Box display="flex" flexDirection="row" alignItems="center">
          <Logo
            color={`${theme}.peripheralText`}
            to={!external && ctaLink}
            href={external && ctaLink}
          >
            {`${callToActionText}`}
          </Logo>
          <StyledRightArrow borderColor="grey60" ml={1} />
        </Box>
      )
    case 'Button':
      return (
        <LabelButton
          margin="0"
          to={!external && ctaLink}
          href={external && ctaLink}
        >
          {callToActionText}
        </LabelButton>
      )
    default:
      return null
  }
}

const DateTimeAndShare = ({
  authorName,
  authorSlug,
  date,
  theme,
  link,
  external,
  authorBy,
}) => {
  let sanitizedLink = link?.[0] === '/' ? link?.substring(1) : link
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent={date || authorName ? 'space-between' : 'flex-end'}
      alignItems="center"
      mt={2}
    >
      <Box display="flex" flexDirection="row">
        {authorName && (
          <Box
            display="flex"
            color={`${theme}.textAlt`}
            flexDirection="row"
            mr={3}
          >
            <StyledP fontSize={1} mr="3px">
              {authorBy}
            </StyledP>
            <Logo
              display="flex"
              alignItems="center"
              justifyContent="center"
              fontSize={1}
              to={authorSlug}
              style={{ textDecoration: 'underline' }}
            >
              {authorName}
            </Logo>
          </Box>
        )}
        {date && (
          <Box fontSize={1} color={`${theme}.peripheralText`}>
            {date}
          </Box>
        )}
      </Box>
      <StyledShare display="flex">
        <ShareButton
          m="0"
          external={external}
          shortUrl={sanitizedLink}
          color="white"
        />
      </StyledShare>
    </Box>
  )
}

const SimpleCtaAndDate = ({
  hideLink,
  displayCta = 'Link',
  ctaText = 'Learn More',
  link,
  theme,
  author,
  external,
  publicationDate,
  authorBy = 'BY',
  displayDateAndShare = false,
}) => {
  return (
    <Box mt={3}>
      {!hideLink && displayCta !== 'No CTA' && (
        <Cta
          callToActionAppearance={displayCta}
          callToActionText={ctaText}
          ctaLink={link}
          external={external}
          theme={theme}
        />
      )}
      {displayDateAndShare && (
        <DateTimeAndShare
          authorName={author?.name}
          authorSlug={author?.slug}
          date={publicationDate}
          theme={theme}
          link={link}
          external={external}
          authorBy={authorBy}
        />
      )}
    </Box>
  )
}

SimpleCtaAndDate.propTypes = {
  author: PropTypes.shape({
    id: PropTypes.string,
    excerpt: PropTypes.string,
    name: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string,
  }),
  hideLink: PropTypes.string,
  displayCta: PropTypes.string,
  ctaText: PropTypes.string,
  link: PropTypes.string,
  theme: PropTypes.string,
  publicationDate: PropTypes.string,
  authorBy: PropTypes.string,
  displayDateAndShare: PropTypes.bool,
}

export default SimpleCtaAndDate
