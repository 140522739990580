import React, { useContext } from 'react'
import Box from '@components/Box'
import { ErrorMessage, useField } from 'formik'
import { StyledLabel, StyledField, StyledGroupTitle } from '../styles.js'
import css from '@styled-system/css'
import { ThemeContext } from 'styled-components'

export const Label = ({ children, theme, ...rest }) => {
  const themePackage = useContext(ThemeContext)

  const checkedColor = themePackage?.colors?.[theme]?.formFocus
  return (
    <StyledLabel
      checkedColor={checkedColor}
      display="block"
      fontSize="15px"
      fontFamily="headings"
      fontWeight={1}
      lineHeight={0}
      letterSpacing="2px"
      color={`${theme}.text` || 'black'}
      textTransform="uppercase"
      m={0}
      {...rest}
    >
      {children}
    </StyledLabel>
  )
}

export const Field = ({
  children,
  theme,
  isRequired,
  name,
  customStyles,
  ...rest
}) => {
  const [, meta] = useField(name)

  return (
    <StyledField
      aria-invalid={meta.error && meta.touched ? 'true' : null}
      aria-describedby={meta.error && meta.touched ? `${name}-error` : null}
      aria-required={isRequired}
      boxsizing="inherit"
      border="solid 1px"
      borderColor={`${theme}.borderAlt`}
      width="100%"
      fontSize="16px"
      fontFamily="body"
      fontWeight={1}
      color={`${theme}.textInvert` || 'darkestBlack'}
      bg={'white'}
      m={0}
      mt={2}
      p={2}
      css={css({
        resize: 'none',
        outline: 'none',
        ...customStyles,
      })}
      name={name}
      {...rest}
    >
      {children}
    </StyledField>
  )
}

export const Error = ({ name, theme, ...rest }) => {
  return (
    <Box
      fontSize={1}
      fontFamily="body"
      fontWeight={1}
      color={`${theme}.alert` || 'alert'}
      m={0}
      pt={2}
      minHeight="2.5rem"
      {...rest}
    >
      <ErrorMessage name={name} />
    </Box>
  )
}

export const GroupTitle = ({ children, theme, ...rest }) => {
  return (
    <StyledGroupTitle
      display="block"
      fontSize={1}
      fontFamily="headings"
      fontWeight={1}
      lineHeight={0}
      letterSpacing="2px"
      color={`${theme}.text` || 'black'}
      textTransform="uppercase"
      m={0}
      mb={3}
      {...rest}
    >
      {children}
    </StyledGroupTitle>
  )
}
