import React from 'react'

import Box from '@components/Box'
import Image from '@components/Image'

import {
  StyledImgVideoContainer,
  StyledImgContainer,
  StyledVideoContainer,
} from '../styles'

import { LinkWrapper } from '@components/Button'
import Video from '@components/Video'
import { useHover, useDelayedUnmounting } from '@hooks/'

const SimpleMedia = ({ image, alt, video, fullWidth, link, external }) => {
  const [hoverRef, isHovered] = useHover()
  const showVideo = useDelayedUnmounting(isHovered, 1500)

  return (
    <LinkWrapper
      disabled={!link}
      to={!external && link}
      href={external && link}
    >
      <Box width="100%" mr={2} mb={2}>
        <StyledImgVideoContainer
          overflow="hidden"
          width={fullWidth ? '100vw' : '100%'}
          bg={!image && 'grey50'}
          position="relative"
          fullWidth={fullWidth}
          ref={hoverRef}
        >
          {video && showVideo && (
            <StyledVideoContainer
              position="absolute"
              top="0"
              bottom="0"
              left="0"
              right="0"
              zIndex="-1"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Video
                {...video}
                src={video?.src}
                thumbnail={video?.thumbnail}
                externalSource={video?.externalSource}
                size="Full Width"
                muted={1}
                videoBehavior="Looping w/o sound"
                videoFit="Contain"
              />
            </StyledVideoContainer>
          )}
          <StyledImgContainer zIndex="1" hasVideo={video}>
            <Image {...image} size="Large" />
          </StyledImgContainer>
        </StyledImgVideoContainer>
      </Box>
    </LinkWrapper>
  )
}

SimpleMedia.propTypes = {}

export default SimpleMedia
