import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '@components/Box'
import { StyledContainer, StyledYtContainer } from './styles'

const Embed = ({ embed, fullWidth }) => {
  useEffect(() => {
    const instagramScript = document.createElement('script')
    const twitterScript = document.createElement('script')
    if (
      // just add these once
      !document.querySelector('#instagramScript') &&
      !document.querySelector('#twitterScript')
    ) {
      instagramScript.id = 'instagramScript'
      instagramScript.type = 'text/javascript'
      instagramScript.onload = () => {
        if (window?.instgrm?.Embeds) {
          window.instgrm.Embeds.process()
        }
      }
      instagramScript.src = 'https://www.instagram.com/embed.js'

      instagramScript.async = true
      document.body.appendChild(instagramScript)

      twitterScript.id = 'twitterScript'
      twitterScript.type = 'text/javascript'
      twitterScript.onload = () => {
        if (window?.twttr?.widgets) {
          window.twttr.widgets.load()
        }
      }
      twitterScript.src = 'https://platform.twitter.com/widgets.js'
      twitterScript.async = true
      document.body.appendChild(twitterScript)

      return () => {
        document.body.removeChild(instagramScript)
        document.body.removeChild(twitterScript)
      }
    }
  }, [])

  let ytVideo = false;

  if (ytVideo) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mb={3}
        width="100%"
      >
        <StyledYtContainer
          position={'relative;'}
          pb={'56.25%'} /* 16:9 */
          height={'0'}
          overflow={'hidden'}
          dangerouslySetInnerHTML={{ __html: embed?.embed || embed }}
        />
      </Box>
    )
  } else {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mb={3}
        width="100%"
      >
        <StyledContainer
          display="flex"
          justifyContent="center"
          width={fullWidth ? '100vw' : "100%"}
          dangerouslySetInnerHTML={{ __html: embed?.embed || embed }}
        />
      </Box>
    )
  }
}

Embed.propTypes = {
  embed: PropTypes.string,
}

export default Embed
