import styled from 'styled-components'
import {
  space,
  position,
  color,
  typography,
  border,
  layout,
  flexbox,
  variant,
} from 'styled-system'

export const StyledStatsItemContainer = styled.div`
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
  ${({ borderBreakpointStyles }) =>
    borderBreakpointStyles === 'single'
      ? `border-right: 0;`
      : borderBreakpointStyles === 'double'
      ? `&:nth-child(2n) {
         border-right: 0;
         }
      `
      : `&:last-child{
         border-right: 0;
        }`}
`

export const StyledTitle = styled.h2`
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`
export const StyledH3 = styled.h3`
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledP = styled.p`
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`
export const StyledTraditionalContainer = styled.div`
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
  box-sizing: border-box;
  &:nth-child(3n){
    padding-right: 0;
  }
`

export const StyledTable = styled.table`
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledTableRow = styled.tr`
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledTableHeader = styled.th`
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledTableData = styled.td`
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`
export const StyledColGroup = styled.colgroup``
export const StyledCol = styled.col``
