import React from 'react'
import PropTypes from 'prop-types'
import Box from '@components/Box'
import CardGroupGatsbyCDAContainer from '@containers/CardGroup'
import SoundCloudGatsbyCDAContainer from '@containers/SoundCloud'
import ListGatsbyCDAContainer from '@containers/List'
import { useLocale } from '@hooks/'
import { formatContentGroupUrls } from '@utils/dataFormatting'

const ContentGroupGatsbyCDAContainer = ({
  layout,
  items,
  preview,
  numberOfItemsFromFeedToPull: limit,
  displayTitle,
  ...fields
}) => {
  //  sanitized url on this level because it's common
  const { localeSlug } = useLocale()
  items = items?.filter(item => Object.keys(item).length !== 0)
  items = limit ? items?.slice(0, limit) : items
  items = formatContentGroupUrls(items, preview, localeSlug)

  const handleRenderSlicedLayoutItems = (items, numberOfColumnsPerRow) => {
    let incrementalAmount = numberOfColumnsPerRow
    let nodesArr = []
    let tempStartIndex = 0

    for (let i = 0; i < items.length; i++) {
      let tempEndIndex = tempStartIndex + incrementalAmount
      if (tempEndIndex <= items.length) {
        // numberColumnsPerRow goes in evenly to Items.length
        nodesArr.push(
          <CardGroupGatsbyCDAContainer
            key={`${items?.title || 'cards'}${i}`}
            {...fields}
            displayTitle={i === 0 ? displayTitle : false}
            items={items.slice(tempStartIndex, tempEndIndex)}
            noBody
          />
        )
        tempStartIndex = tempEndIndex
      } else {
        nodesArr.push(
          <CardGroupGatsbyCDAContainer
            key={`${items?.title || 'cards'}${i}`}
            {...fields}
            displayTitle={i === 0 ? displayTitle : false}
            items={items.slice(tempStartIndex, tempEndIndex)}
            noBody
          />
        )
        return nodesArr
      }
    }
    return nodesArr
  }

  switch (layout) {
    case '50:50':
      return (
        <Box
          mt={process.env.GATSBY_SPACECAMP && 3}
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
        >
          {handleRenderSlicedLayoutItems(items, 2)}
        </Box>
      )
    case '33:33:33':
      return (
        <Box
          mt={process.env.GATSBY_SPACECAMP && 3}
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
        >
          {handleRenderSlicedLayoutItems(items, 3)}
        </Box>
      )
    case '25:25:25:25':
      return (
        <Box
          mt={process.env.GATSBY_SPACECAMP && 3}
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
        >
          {handleRenderSlicedLayoutItems(items, 4)}
        </Box>
      )
    case 'Automatic Formatting Based On Amount of Cards Added':
      return (
        <CardGroupGatsbyCDAContainer
          {...fields}
          displayTitle={displayTitle}
          items={items}
        />
      )
    case 'Card Group - Full':
      return (
        <CardGroupGatsbyCDAContainer
          {...fields}
          displayTitle={displayTitle}
          items={items}
        />
      )
    case 'Card Group - Minimal':
      return (
        <CardGroupGatsbyCDAContainer
          {...fields}
          displayTitle={displayTitle}
          items={items}
          noBody
        />
      )
    case 'Card Group - No Media':
      return (
        <CardGroupGatsbyCDAContainer
          {...fields}
          displayTitle={displayTitle}
          items={items}
          noMedia
        />
      )
    case 'Soundcloud':
      return (
        <SoundCloudGatsbyCDAContainer
          {...fields}
          displayTitle={displayTitle}
          items={items}
        />
      )
    case 'List - Full':
      return (
        <ListGatsbyCDAContainer
          {...fields}
          layout={layout}
          displayTitle={displayTitle}
          items={items}
        />
      )
    case 'List - Minimal':
      return (
        <ListGatsbyCDAContainer
          {...fields}
          layout={layout}
          displayTitle={displayTitle}
          items={items}
        />
      )
    default:
      return null
  }
}

ContentGroupGatsbyCDAContainer.propTypes = {
  layout: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
}

export default ContentGroupGatsbyCDAContainer
