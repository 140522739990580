import React from 'react'
import PropTypes from 'prop-types'
import TwoColumn from '@components/TwoColumn'
import { useMobile } from '@hooks'

const ModuleTwoColumnGatsbyCDAContainer = props => {
  return <TwoColumn {...props} />
}

ModuleTwoColumnGatsbyCDAContainer.propTypes = {}

export default ModuleTwoColumnGatsbyCDAContainer
