import React from 'react'
import PropTypes from 'prop-types'
import Box from '@components/Box'
import { Label, Field, Error } from './Input'

const InputTextArea = ({
  theme,
  layout,
  label,
  name,
  placeholder,
  isRequired,
}) => {
  return (
    <Box display="block">
      <Label theme={theme} htmlFor={name}>
        {label}
        {isRequired && '*'}
      </Label>
      <Field
        theme={theme}
        component="textarea"
        rows="12"
        verticalAlign="top"
        name={name}
        placeholder={placeholder}
        isRequired={isRequired}
        customStyles={{
          '&:focus': { color: 'black', bg: 'white', border: 'solid 1px black' },
          transition: 'background-color 0.2s, boder-color 0.2s',
        }}
      />
      <Error theme={theme} name={name} />
    </Box>
  )
}

InputTextArea.propTypes = {
  theme: PropTypes.string,
  layout: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
}

export default InputTextArea
