import React from 'react'
import PropTypes from 'prop-types'
import CarouselSlickBtn from './CarouselSlickBtn'
import { StyledButtonWidth, StyledArrowButtonContainer } from '../styles'
import Box from '@components/Box'

const CarouselButtons = ({ slider, theme = 'light', direction }) => {
  return (
    <StyledArrowButtonContainer
      position="absolute"
      top="0"
      left="0"
      right="0"
      bottom="0"
      display="flex"
      width="100%"
      justifyContent="center"
    >
      <StyledButtonWidth
        display="flex"
        justifyContent={direction === 'left' ? 'flex-start' : 'flex-end'}
        zIndex={3}
        maxWidth={[
          'maxWidth.xsmall',
          'maxWidth.small',
          'maxWidth.medium',
          'maxWidth.large',
          'maxWidth.xlarge',
          'maxWidth.xxlarge',
        ]}
        height="fit-content"
        position="absolute"
        top="50%"
        bottom="0"
        width="100%"
      >
        {direction === 'left' && (
          <CarouselSlickBtn
            onClick={() => slider?.current?.slickPrev()}
            direction="left"
            theme={theme}
          />
        )}
        {direction === 'right' && (
          <CarouselSlickBtn
            onClick={() => slider?.current?.slickNext()}
            direction="right"
            theme={theme}
          />
        )}
      </StyledButtonWidth>
    </StyledArrowButtonContainer>
  )
}

CarouselButtons.propTypes = {
  slider: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  theme: PropTypes.string,
  direction: PropTypes.string,
}

export default CarouselButtons
