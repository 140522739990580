import React from 'react'
import PropTypes from 'prop-types'
import TableStatsBlock from './components/Stats/TableStatsBlock'
import TableTraditionalBlock from './components/Traditional/TableTraditionalBlock'
import TableAccordionBlock from './components/Accordion/TableAccordionBlock'

const TableBlock = ({ layout, ...rest }) => {
  switch (layout) {
    case 'Accordion':
      return <TableAccordionBlock {...rest} />
    case 'Traditional':
      return <TableTraditionalBlock {...rest} />
    case 'Stats':
      return <TableStatsBlock {...rest} />
    default:
      return null
  }
}

TableBlock.propTypes = {
  layout: PropTypes.string,
}

export default TableBlock
