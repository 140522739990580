import React from 'react'
import PropTypes from 'prop-types'
import CardGroup from '@components/CardGroup'
import { formatDate, formatTime } from '@utils'
import { useTranslatedTerms, useMobile } from '@hooks/'
import { parseVideo } from '@utils/'

const CardGroupApolloCPAContainer = ({ items, theme, ...rest }) => {
  const { getTranslatedTerm } = useTranslatedTerms()
  theme = theme?.toLowerCase()

  items = items?.map(
    ({
      label,
      publicationDate,
      image,
      type,
      author,
      category,
      aNetworkCompany,
      video,
      ...rest
    }) => ({
      ...rest,
      type: type,
      author: {
        ...author,
        slug: author?.slug,
      },
      category:
        process.env.GATSBY_SPACECAMP && aNetworkCompany
          ? aNetworkCompany
          : category,
      publicationDate:
        publicationDate &&
        `${formatDate(publicationDate)}, ${formatTime(publicationDate)}`,
      image: { src: image?.sourceLandscape?.file?.url },
      video: video && parseVideo(video, { orientation: 'landscape' }),
      ctaText:
        type === 'ContentfulArticle'
          ? getTranslatedTerm('ctaArticle')
          : getTranslatedTerm('ctaDefault'),
      external: type === 'ContentfulComponentExternalLink',
    })
  )

  return <CardGroup {...rest} items={items} theme={theme} cardLayout="Group" />
}

CardGroupApolloCPAContainer.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  theme: PropTypes.string,
}

export default CardGroupApolloCPAContainer
