import React from 'react'
import PropTypes from 'prop-types'
import Video from '@components/Video'
import useMobile from '@hooks/useMobile'
import { parseVideo } from '@utils/media'

const ColumnVideo = video => {
  const [isMobile] = useMobile()

  const parsedVideo = parseVideo(video, { isMobile })

  return <Video {...parsedVideo} size="Full Width" />
}

ColumnVideo.propTypes = {}

export default ColumnVideo
