import styled from 'styled-components'
import {
  space,
  position,
  color,
  typography,
  border,
  layout,
  flexbox,
  variant,
} from 'styled-system'
import Image from '@components/Image'

import Lightbox from 'react-spring-lightbox'

export const StyledLightbox = styled(Lightbox)`
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledClose = styled.div`
  pointer-events: auto;
  cursor: pointer;  
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledPlayContainer = styled.div`
  transform: translate(-50%, -50%);
  cursor: pointer;  
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const MediaContainer = styled.div`
  button {
    ${({ disableFocus }) => disableFocus && 'display: none !important;'}
  }
  ${({ crop }) =>
    crop &&
    crop !== 'No Crop' &&
    `
    &::after {
    display: block;
    content: '';
    padding-bottom: ${crop === '1:1' ? '100%' : '56.25%'};
    width: 100%;
  }`}
  
  ${space}
  ${position}
  ${layout}
  ${color}
  ${flexbox}
`

export const StyledArrowContainer = styled.div`
  cursor: pointer;  
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledLightboxContainer = styled.div`
  button {
    ${({ disableFocus }) => disableFocus && 'display: none !important;'}
  }
  .vjs-control-bar {
    ${({ disableFocus }) => disableFocus && 'display: none !important;'}
  }
  @media (max-width: 35em){
    width: 400px;
  }

  @media (max-width: 33em){
    width: 300px;
  }

  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledImageContainer = styled.div`
  cursor: ${({ cursor = 'default' }) => cursor};
 
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledPlayButton = styled(Image)`
  padding: 0;
  margin: 0;
  ${space}
  ${color}
  ${typography}
  ${position}
  ${border}
  ${layout}
  ${flexbox}

`
