import React from 'react'
import PropTypes from 'prop-types'
import Box from '@components/Box'
import TableStats from './TableStats'
import { Title } from '@components/RichTextAndTypography'

const TableStatsBlock = ({ title = '', tables = [], theme = 'light' }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      alignItems="center"
      pt={['20px', '40px']}
    >
      {process.env.GATSBY_SPACECAMP ? null : (
        <Title
          m={0}
          textAlign="center"
          color={`${theme}.text`}
        >
          {title}
        </Title>
      )}
      <Box width="100%">
        {tables.map(({ id, ...table }) => (
          <TableStats key={id} {...table} theme={theme} />
        ))}
      </Box>
    </Box>
  )
}

TableStatsBlock.propTypes = {
  tables: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      tableRows: PropTypes.arrayOf(PropTypes.object),
    })
  ),
  title: PropTypes.string,
  theme: PropTypes.string,
}

export default TableStatsBlock
