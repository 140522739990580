import styled from 'styled-components'
import {
  space,
  position,
  color,
  typography,
  border,
  layout,
  flexbox,
  variant,
} from 'styled-system'

export const StyledContainer = styled.div`
  box-sizing: border-box;
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${variant}
`

export const StyledImgContainer = styled(StyledContainer)`
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
  ${({ hasVideo }) =>
    hasVideo &&
    `&:hover {
    opacity: 0;
  }`}
`

export const StyledVideoContainer = styled(StyledContainer)`
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
  &:hover {
    opacity: 0;
  }
`

export const StyledImgVideoContainer = styled(StyledContainer)`
  ${({ fullWidth }) => fullWidth && 'left: 50%; margin-left: -50vw;'}
`

export const StyledA = styled.a`
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${variant}
`
export const StyledP = styled.p`
  padding: 0;
  margin: 0;
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${variant}

  ${({ clamplines }) =>
    clamplines &&
    `
      display: -webkit-box;
      -webkit-line-clamp: ${clamplines};
      -webkit-box-orient: vertical;  
      overflow: hidden;
    `}
`
export const StyledCategoryLink = styled(StyledA)`
  padding: 0;
  margin: 0;
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${variant}
`

export const StyledAName = styled(StyledA)`
  text-decoration: underline;
`
export const StyledRightArrow = styled.div`
  border: solid;
  padding: 3px;
  transform: rotate(-45deg);  
  border-width: 0 2px 2px 0;
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${variant}
`

export const StyledShare = styled.div`
  cursor: pointer;
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${variant}
`
export const StyledOL = styled.ol`
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${variant}
`

export const StyledLI = styled.li`
  &:last-child {
    margin-bottom: 0;
  }
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.1);
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${variant}
`

export const StyledH3 = styled.p`
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${variant}
`
