import * as Yup from 'yup'
import { camelize } from '@utils/'

export const layouts = {
  CONTACT: 'Contact',
  SUBSCRIBE: 'Subscribe',
  FOOTER: 'Footer',
}

export const validations = {
  AT_LEAST_8_CHARS: 'Input must be at least 8 characters',
  REQUIRED: 'Input must not be empty',
}

/*
 * VALUES
 */
export const initialValues = fields => {
  const initialValues = {}
  const stateValues = window?.history?.state

  fields.forEach(field => {
    switch (field?.type) {
      case 'Checkbox':
        field.options.forEach(
          option => (initialValues[camelize(option)] = false)
        )
        break
      case 'Dropdown':
        initialValues[camelize(field?.title)] = ''
        break
      default:
        if (stateValues?.values) {
          Object.keys(stateValues?.values).includes(camelize(field?.title))
            ? (initialValues[camelize(field?.title)] =
                stateValues?.values[camelize(field?.title)])
            : (initialValues[camelize(field?.title)] = '')
        } else {
          initialValues[camelize(field?.title)] = ''
        }
        break
    }
  })

  return initialValues
}

/*
 * VALIDATION
 */
export const validationSchema = fields => {
  const validationSchema = {
    isEmail: Yup.boolean(),
    isRequired: Yup.boolean(),
    isEightChars: Yup.boolean(),
  }

  fields.forEach(field => {
    switch (field?.type) {
      case 'Radio':
      case 'Dropdown':
      case 'Email':
      case 'Tel':
        validationSchema[camelize(field?.title)] = Yup.string()
          .when('isEmail', {
            is: () => field?.type === 'Email',
            then: Yup.string().email('Invalid Email'),
          })
          .when('isRequired', {
            is: () =>
              field?.validation?.includes(validations.REQUIRED) === true,
            then: Yup.string().required('Required'),
          })
        break
      case 'Checkbox':
        field.options.forEach(option => {
          validationSchema[camelize(option)] = Yup.boolean().when(
            'isRequired',
            {
              is: () =>
                field?.validation?.includes(validations.REQUIRED) === true,
              then: Yup.boolean().oneOf([true], 'Required'),
            }
          )
        })
        break
      default:
        validationSchema[camelize(field?.title)] = Yup.string()
          .when('isEightChars', {
            is: () =>
              field?.validation?.includes(validations.AT_LEAST_8_CHARS) ===
              true,
            then: Yup.string().min(8, 'Must be 8 characters or more'),
          })
          .when('isRequired', {
            is: () =>
              field?.validation?.includes(validations.REQUIRED) === true,
            then: Yup.string().required('Required'),
          })
        break
    }
  })

  return Yup.object(validationSchema)
}
