import styled, { css } from 'styled-components'
import {
  space,
  position,
  color,
  typography,
  border,
  layout,
  flexbox,
  system,
  grid,
} from 'styled-system'
import Box from '@components/Box'
import { Field, Form } from 'formik'
import Select from 'react-select'
import theme from '@theme/'
import { hexToRgba } from '@utils/'

const textTransform = system({ textTransform: { property: 'textTransform' } })
const boxsizing = system({ boxsizing: { property: 'boxSizing' } })

const menuOpenAnimation = css`
  @keyframes fadeIn {
    0% {
      border: ${`1px solid ${hexToRgba(theme.colors.black, 0)}`};
      border-top: none;
    }
    100% {
      border: ${`1px solid ${hexToRgba(theme.colors.black, 1)}`};
      border-top: none;
    }
  }

  .menu {
    animation: fadeIn 0.2s;
    animation-fill-mode: forwards;
  }
`

export const StyledHolyGrailLayout = styled.div`
  ${space}
  ${position}
  ${layout}
  ${border}
  ${typography}
  ${color}
  ${flexbox}
  ${grid}
  ${textTransform}

  display: flex;
  flex-direction: column;

@media (min-width: 768px) {
    flex-direction: row;
    flex: 1;
  }
`

export const StyledSelect = styled(Select)`
  ${menuOpenAnimation}
`

export const StyledForm = styled(Form)`
  ${space}
  ${position}
  ${layout}
  ${border}
  ${typography}
  ${color}
  ${flexbox}
  ${grid}
  ${textTransform}
  input:-webkit-autofill {
    background-color: black !important;
  }
`

export const StyledField = styled(Field)`
  ${boxsizing}
  ${space}
  ${position}
  ${layout}
  ${border}
  ${typography}
  ${color}
  ${flexbox}
  ${grid}
  ${textTransform}

`

export const StyledErrorMessage = styled(Box)`
  ${space}
  ${position}
  ${layout}
  ${border}
  ${typography}
  ${color}
  ${flexbox}
  ${grid}
  ${textTransform}
`

export const StyledMailPhoneLinks = styled.a`
  color: white;
`
export const StyledLabel = styled.label`
  ${space}
  ${position}
  ${layout}
  ${border}
  ${typography}
  ${color}
  ${flexbox}
  ${grid}
  ${textTransform}

  ${({ type }) =>
    type &&
    (type === 'checkbox' || type === 'radio') &&
    `
      cursor: pointer;
      user-select: none;

      input { 
        cursor: pointer; 
      }
    `}

  ${({ type, checkedColor }) =>
    type &&
    type === 'checkbox' &&
    `
      input ~ ${StyledSpan} svg { 
        opacity: 0; 
        transition: opacity 0.1s
      }
      
      input:checked ~ ${StyledSpan} svg { 
        opacity: 1; 
      }

      input:checked ~ ${StyledSpan} { 
        ${checkedColor && `background: ${checkedColor};`}
        
      }
    `}

  ${({ type, checkedColor }) =>
    type &&
    type === 'radio' &&
    `
      input ~ ${StyledSpan}:after { 
        content: "";
        position: absolute;
        opacity: 0;
        transition: opacity 0.1s;
      }
      
      input:checked ~ ${StyledSpan}:after { 
        opacity: 1;
      }

      input:checked ~ ${StyledSpan} { 
        ${checkedColor && `background: ${checkedColor};`}
        
      }

      ${StyledSpan}:after { 
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: black;
      }
    `}
`

export const StyledGroupTitle = styled(Box)`
  ${space}
  ${position}
  ${layout}
  ${border}
  ${typography}
  ${color}
  ${flexbox}
  ${grid}
  ${textTransform}
`

export const StyledSpan = styled.span`
  ${space}
  ${position}
  ${layout}
  ${border}
  ${typography}
  ${color}
  ${flexbox}
  ${grid}
  ${textTransform}

`
