import React from 'react'
import PropTypes from 'prop-types'
import SimpleCard from './components/SimpleCard'
import SoundCloud from './components/SoundCloud'
import ListCardFull from './components/ListCardFull'
import ListCardMinimal from './components/ListCardMinimal'

const Card = ({ cardLayout = 'Card Group', ...rest }) => {
  switch (cardLayout) {
    case 'Soundcloud':
      return <SoundCloud {...rest}  />
    case 'List - Full':
      return <ListCardFull {...rest}  />
    case 'List - Minimal':
      return <ListCardMinimal {...rest}  />
    default:
      return <SimpleCard {...rest}  />
  }
}

Card.propTypes = {
  cardLayout: PropTypes.string,
}

export default Card
