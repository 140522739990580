import React from 'react'
import PropTypes from 'prop-types'
import Box from '@components/Box'
import { renderRichText } from '@components/RichTextAndTypography'

const ColumnText = ({ body }) => {
  if (!body) return null
  return (
    <Box>
      {renderRichText({
        richText: body?.json || body,
      })}
    </Box>
  )
}

ColumnText.propTypes = {}

export default ColumnText
