import React from 'react'
import PropTypes from 'prop-types'
import Box from '@components/Box'
import { Label, Field, Error, GroupTitle } from './Input'
import { camelize } from '@utils/'
import Icon from '@components/Icon'
import { StyledSpan } from '../styles.js'

// TODO:
// Complete Checkbox Group

const InputCheckbox = ({ theme, layout, label, name, options, isRequired }) => {
  return (
    <Box>
      {options?.length > 1 && <GroupTitle>{label}</GroupTitle>}
      <Box display="grid" gridTemplateColumns="1fr 1fr">
        {options?.map((option, i) => {
          return (
            <Box key={i}>
              <Label
                theme={theme}
                type="checkbox"
                display="block"
                position="relative"
                pl="28px"
                mb={1}
                textTransform="capitalize"
                fontSize={2}
                letterSpacing="normal"
              >
                <Field
                  theme={theme}
                  name={camelize(option)}
                  type="checkbox"
                  position="absolute"
                  opacity="0"
                  height="0"
                  width="0"
                  isRequired={isRequired}
                />
                <StyledSpan
                  position="absolute"
                  top="0"
                  left="0"
                  height="20px"
                  width="20px"
                  background-color="white"
                  color="darkestBlack"
                  border="solid 1px black"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  bg="white"
                >
                  <Icon glyph="close" viewBox="8 8 16.03 14.8" margin="0" />
                </StyledSpan>
                <Box pt="4px">
                  {option}
                  {isRequired && '*'}
                </Box>
              </Label>
              <Error theme={theme} name={camelize(option)} />
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

InputCheckbox.propTypes = {
  theme: PropTypes.string,
  layout: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  isRequired: PropTypes.bool,
}

export default InputCheckbox
