/* eslint-disable react/no-children-prop */
/* eslint-disable react/display-name */
import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import Box from '@components/Box'
import { useMobile } from '@hooks'
import {
  Headline,
  QuoteIntro,
  H2,
  H3,
  H4,
  H5,
  H6,
  B,
  U,
  I,
  UL,
  LI,
  OL,
} from './index.js'

import EmbeddedEntryContainer from '@containers/EmbeddedEntry'
import { PreviewContext } from '@context/index.js'
import { RichtextHyperlink } from '@components/Button/index.js'
import {
  formatPreviewSlug,
  recursivelyDestructureFields,
} from '@utils/index.js'
import { useLocale, usePreviewQuery } from '@hooks/index.js'
import { GET_ENTRY_BY_ID } from '@hooks/usePreviewQuery.js'

const ParagraphBlock = ({ children, theme }) => {
  return (
    <Box
      fontSize={[3, '24px']}
      fontFamily="headings"
      lineHeight="1.4"
      fontWeight={200}
      letterSpacing="0.23px"
      paddingBottom="20px"
      textShadow={theme === 'dark' && 'text'}
      w={[1]}
      px={[0, 0, 0, 0]}
    >
      {children}
    </Box>
  )
}

const Hyperlink = ({ children, node }) => {
  const { locale, defaultLocale, localeSlug } = useLocale()
  const preview = useContext(PreviewContext)

  const id = node?.data?.target?.sys?.id
  const { loading, error, data } = usePreviewQuery({
    query: GET_ENTRY_BY_ID,
    id: id,
    skip: !id,
  })
  const [contentType, setContentType] = useState({})

  useEffect(() => {
    if (!loading && !error && data) {
      const { items } = data.entry
      const type = items?.[0]?.sys?.contentType?.sys?.id
      setContentType(type)
    }
  }, [data, error, loading])

  const href = node?.data?.uri
  let to = null

  if (preview) {
    const id = node?.data?.target?.sys?.id
    to = `${localeSlug}/${formatPreviewSlug(id, contentType)}`
  } else {
    const data = node?.data?.target
    const fields =
      data && recursivelyDestructureFields(data, locale, defaultLocale)
    to = fields?.slug
  }

  return (
    <RichtextHyperlink to={to} href={href}>
      {children}
    </RichtextHyperlink>
  )
}

const options = ({ theme, isMobile, isArticle, references }) => ({
  renderText: text =>
    text
      .split('\n')
      .reduce(
        (children, textSegment, index) => [
          ...children,
          index > 0 && <br key={index} />,
          textSegment,
        ],
        []
      ),
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <ParagraphBlock theme={theme}>{children}</ParagraphBlock>
    ),
    [BLOCKS.HEADING_1]: (node, children) => {
      return (
        <Headline theme={theme} isMobile={isMobile} isArticle={isArticle}>
          {children}
        </Headline>
      )
    },
    [BLOCKS.HEADING_2]: (node, children) => (
      <H2 theme={theme} isMobile={isMobile} isArticle={isArticle}>
        {children}
      </H2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <H3 theme={theme} isMobile={isMobile} isArticle={isArticle}>
        {' '}
        {children}
      </H3>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <H4 theme={theme} isMobile={isMobile}>
        {' '}
        {children}
      </H4>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <H5 theme={theme} isMobile={isMobile}>
        {children}
      </H5>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <H6 theme={theme} isMobile={isMobile}>
        {children}
      </H6>
    ),
    [BLOCKS.UL_LIST]: (node, children) => <UL>{children}</UL>,
    [BLOCKS.OL_LIST]: (node, children) => <OL>{children}</OL>,
    [BLOCKS.LIST_ITEM]: (node, children) => <LI>{children}</LI>,
    [BLOCKS.QUOTE]: (node, children) => (
      <QuoteIntro theme={theme} isMobile={isMobile}>
        {children}
      </QuoteIntro>
    ),
    [BLOCKS.HR]: () => <Box py={['10px', 5]} />,
    [INLINES.HYPERLINK]: (node, children) => (
      <Hyperlink node={node}>{children}</Hyperlink>
    ),
    [INLINES.ENTRY_HYPERLINK]: (node, children) => (
      <Hyperlink node={node}>{children}</Hyperlink>
    ),
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      return (
        <EmbeddedEntryContainer
          node={node}
          theme={theme}
          references={references}
        />
      )
    },
  },
  renderMark: {
    [MARKS.BOLD]: text => (
      <B
        color={
          process.env.GATSBY_SPACECAMP
            ? `${theme}.heroAlt`
            : theme === 'dark'
            ? `${theme}.text`
            : `${theme}.textAlt`
        }
        fontWeight={600}
      >
        {text}
      </B>
    ),
    [MARKS.ITALIC]: text => (
      <I color={`${theme}.text`} fontWeight={400}>
        {text}
      </I>
    ),
    [MARKS.UNDERLINE]: text => (
      <U color={`${theme}.text`} fontWeight={600}>
        {text}
      </U>
    ),
  },
})

export const renderRichText = ({
  richText,
  references,
  theme,
  isMobile,
  bgContentType,
  preview,
  isArticle = false,
}) => {
  if (!richText) return null
  if (richText.hasOwnProperty('json') && !richText?.json) return null

  // Case for preview (only has `json`)
  // Case for array mapped rich text (each entry has its own rich text container)
  // richtText.raw is pre-parsed and references are forwarded (see Content.js for example)
  if (!richText?.raw)
    return documentToReactComponents(
      richText,
      options({
        theme,
        isMobile,
        bgContentType,
        preview,
        isArticle,
        references,
      })
    )

  // Case where richText is in the shape {raw, references}
  // Gatsby-source-contentful generates `raw` and `references`
  return documentToReactComponents(
    JSON.parse(richText?.raw),
    options({
      theme,
      isMobile,
      bgContentType,
      preview,
      isArticle,
      references: richText?.references,
    })
  )
}
const RichText = ({
  richText,
  theme = 'light',
  displayBorder = false,
  bgContentType = false,
  margin = '0 auto',
  maxWidth = [
    'maxWidth.xsmall',
    'maxWidth.small',
    'maxWidth.medium',
    'maxWidth.large',
    'maxWidth.xlarge',
    'maxWidth.xxlarge',
  ],
}) => {
  const [isMobile] = useMobile()

  if (!richText) return null

  return (
    <Box
      className="vertically-padded-box"
      borderWidth={displayBorder ? `2px` : '0px'}
      width="100%"
      maxWidth={maxWidth}
      margin={margin}
      paddingLeft={isMobile && '25px'}
      paddingRight={isMobile && '25px'}
    >
      {renderRichText({
        richText,
        theme: theme?.toLowerCase(),
        isMobile,
        bgContentType,
      })}
    </Box>
  )
}
export default RichText

RichText.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark', 'magenta']),
  richText: PropTypes.object,
  displayBorder: PropTypes.bool,
}
